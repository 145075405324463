import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

// import Login from "../../HomePage/HomePage";
import CreateStudent from "../../Students/CreateStudent/CreateStudent";
import CreateUser from "../../General/CreateUser/CreateUser";
import FundingBodyReceipt from "../../FundingBody/Receipt/Receipt";
import Settings from "../../Settings/Settings";
import PickupLocations from "../../Students/PickupLocations/PickupLocations";
import Agreements from "../../Students/Agreements/Agreements";
import Incomes from "../../Financial/Incomes/Incomes";
import IncomesFromStudents from "../../Financial/IncomesFromStudents/IncomesFromStudents";
import IncomesFromFundingBodies from "../../Financial/IncomesFromFundingBodies/IncomesFromFundingBodies";
import Expenses from "../../Financial/Expenses/Expenses";
import Providers from "../../Financial/Providers/Providers";
import Clients from "../../Financial/Clients/Clients";
import ExpenseTypes from "../../Financial/ExpenseTypes/ExpenseTypes";
import FundingBodies from "../../Financial/FundingBodies/FundingBodies";
import StudentSearch from "../../Students/StudentSearch/StudentSearch";
import Login from "../../Login/Login";
import Calendar from "../../Calendar/Calendar";
import CalendarVehicle from "../../Calendar/CalendarVehicle";
import TeachersCalendar from "../../TeachersCalendar/Calendar";
import GeneralMessages from "../../Students/GeneralMessages/GeneralMessages";
import Users from "../../General/Users/Users";
import CreateVehicle from "../../General/CreateVehicle/CreateVehicle";
import ProfessionalTeachers from "../../General/ProfessionalTeachers/ProfessionalTeachers";
import Vehicles from "../../General/Vehicles/Vehicles";
import Permissions from "../../General/Permissions/Permissions";
import StudentUpdate from "../../Students/StudentUpdate/StudentUpdate";
import TeacherWeekly from "../../Reports/TeacherWeekly/TeacherWeekly";
import TeacherYearly from "../../Reports/TeacherYearly/TeacherYearly";
import DrivingPermits from "../../General/DrivingPermits/DrivingPermits";
import Testers from "../../General/Testers/Testers";
import Cpa from "../../Reports/Cpa/Cpa";
import OdoMeterReport from "../../Reports/OdoMeterReport/OdoMeterReport";
import YearlyActivity from "../../Reports/YearlyActivity/YearlyActivity";
import InvoicesTypesByMonth from "../../Reports/InvoicesTypesByMonth/InvoicesTypesByMonth";
import CanceledReport from "../../Reports/CanceledReport/CanceledReport";
import ActivityReport from "../../Reports/ActivityReport/ActivityReport";
import StudentBalances from "../../Reports/StudentBalances/StudentBalances";
import StudentReport from "../../Reports/StudentReport/StudentReport";
import NewStudentsByDay from "../../Reports/NewStudentsByDay/NewStudentsByDay";
import MonthlyActivity from "../../Reports/MonthlyActivity/MonthlyActivity";
import InternalTestsReport from "../../Reports/InternalTestsReport/InternalTestsReport";
import YearlyDrivingPermitsActivity from "../../Reports/YearlyDrivingPermitsActivity/YearlyDrivingPermitsActivity";
import TestsReport from "../../Reports/TestsReport/TestsReport";
import StudentsByMonth from "../../Reports/StudentsByMonth/StudentsByMonth";
import NewStudentsVSPassedTests from "../../Reports/NewStudentsVSPassedTests/NewStudentsVSPassedTests";
import NewStudentsVSInvoices from "../../Reports/NewStudentsVSInvoices/NewStudentsVSInvoices";
import InactiveStudents from "../../Reports/InactiveStudents/InactiveStudents";
import InactiveNewStudents from "../../Reports/InactiveNewStudents/InactiveNewStudents";
import TestByMonth from "../../Reports/TestByMonth/TestByMonth";
import LessonsByMonth from "../../Reports/LessonsByMonth/LessonsByMonth";
import IncomesByMonth from "../../Reports/IncomesByMonth/IncomesByMonth";
import TestTypesByMonth from "../../Reports/TestTypesByMonth/TestTypesByMonth";
import TestStatistics from "../../Reports/TestStatistics/TestStatistics";
import TrafficLaws from "../../General/TrafficLaws/TrafficLaws";
import OdoMeter from "../../General/OdoMeter/OdoMeter";
import Symbols from "../../General/Symbols/Symbols";
import AdminControl from "../../Admin/Control";
import ActivityAnalysis from "../../Reports/ActivityAnalysis/ActivityAnalysis";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Login} />

      <Route path="/providers" component={Providers} isPrivate />
      <Route path="/createStudent" component={CreateStudent} isPrivate />
      <Route path="/createUser" component={CreateUser} isPrivate />
      <Route path="/pickupLocations" component={PickupLocations} isPrivate />
      <Route path="/calendar" component={Calendar} isPrivate />
      <Route path="/calendar-vehicle" component={CalendarVehicle} isPrivate />
      <Route path="/studentSearch" component={StudentSearch} isPrivate />
      {/* <Route path="/teachersCalendar" component={TeachersCalendar} isPrivate /> */}
      <Route path="/agreements" component={Agreements} isPrivate />
      <Route path="/settings" component={Settings} isPrivate />
      <Route path="/incomes" component={Incomes} isPrivate />
      <Route path="/generalMessages" component={GeneralMessages} isPrivate />
      <Route path="/expenses" component={Expenses} isPrivate />
      <Route path="/providers" component={Providers} isPrivate />
      <Route path="/clients" component={Clients} isPrivate />
      <Route
        path="/incomesFromStudents"
        component={IncomesFromStudents}
        isPrivate
      />
      <Route
        path="/incomesFromFundingBodies"
        component={IncomesFromFundingBodies}
        isPrivate
      />
      <Route path="/expensesTypes" component={ExpenseTypes} isPrivate />
      <Route path="/studentBalances" component={StudentBalances} isPrivate />
      <Route path="/fundingBodies" component={FundingBodies} isPrivate />
      <Route path="/users" component={Users} isPrivate />
      <Route path="/vehicles" component={Vehicles} isPrivate />
      <Route path="/createVehicle" component={CreateVehicle} isPrivate />
      <Route path="/updateStudent" component={StudentUpdate} isPrivate />
      <Route path="/permissions" component={Permissions} isPrivate />
      <Route path="/teacherWeekly" component={TeacherWeekly} isPrivate />
      <Route path="/teacherYearlyReport" component={TeacherYearly} isPrivate />
      <Route path="/drivingPermits" component={DrivingPermits} isPrivate />
      <Route path="/trafficLaws" component={TrafficLaws} isPrivate />
      <Route path="/newStudentsByDay" component={NewStudentsByDay} isPrivate />
      <Route path="/testers" component={Testers} isPrivate />
      <Route path="/cpaReport" component={Cpa} isPrivate />
      <Route path="/yearlyActivity" component={YearlyActivity} isPrivate />
      <Route path="/symbols" component={Symbols} isPrivate />
      <Route path="/canceledReport" component={CanceledReport} isPrivate />
      <Route path="/activityReport" component={ActivityReport} isPrivate />
      <Route path="/studentsReport" component={StudentReport} isPrivate />
      <Route path="/newStudentsByMonth" component={StudentsByMonth} isPrivate />
      <Route path="/odoMeterReport" component={OdoMeterReport} isPrivate />
      <Route
        path="/invoicesTypesByMonth"
        component={InvoicesTypesByMonth}
        isPrivate
      />
      <Route path="/testsReport" component={TestsReport} isPrivate />
      <Route path="/lessonsByMonth" component={LessonsByMonth} isPrivate />
      <Route path="/incomesByMonth" component={IncomesByMonth} isPrivate />
      <Route path="/testStatistics" component={TestStatistics} isPrivate />
      <Route path="/inactiveStudents" component={InactiveStudents} isPrivate />
      <Route
        path="/yearlyDrivingPermitsActivity"
        component={YearlyDrivingPermitsActivity}
        isPrivate
      />
      <Route
        path="/inactiveNewStudents"
        component={InactiveNewStudents}
        isPrivate
      />
      <Route
        path="/newStudentsVSPassedTests"
        component={NewStudentsVSPassedTests}
        isPrivate
      />
      <Route
        path="/newStudentsVSInvoices"
        component={NewStudentsVSInvoices}
        isPrivate
      />
      <Route path="/testByMonth" component={TestByMonth} isPrivate />
      <Route path="/testTypesByMonth" component={TestTypesByMonth} isPrivate />
      <Route path="/odoMeter" component={OdoMeter} isPrivate />
      <Route path="/monthlyActivity" component={MonthlyActivity} isPrivate />
      <Route
        path="/internalTestsReport"
        component={InternalTestsReport}
        isPrivate
      />
      <Route
        path="/professionalTeachers"
        component={ProfessionalTeachers}
        isPrivate
      />

      <Route
        path="/fundingBodyReceipt"
        component={FundingBodyReceipt}
        isPrivate
      />

      <Route
        path="/activityAnalysis"
        component={ActivityAnalysis}
        isPrivate
      />

      <Route
        path="/admin/controls"
        component={AdminControl}
        isPrivate
      />

      <Route component={Login} />
    </Switch>
  );
}
