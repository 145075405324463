import React, { PureComponent, useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { connect } from "react-redux";
import moment from "moment";
import DatePicker from "react-datepicker";
import ErrorModal from "../Modal/ErrorModal";
import NewLesson from "./NewLesson/NewLesson";
import NewCharge from "./NewCharge/NewCharge";
import UpdatePickupLocation from "./UpdatePickupLocation/UpdatePickupLocation";
import NoteChange from "./NoteChange/NoteChange";
import EventStatusChange from "./EventStatusChange/EventStatusChange";
import TeacherChange from "./TeacherChange/TeacherChange";
import VehicleChange from "./VehicleChange/VehicleChange";
import Day from "react-big-calendar/lib/Day";
import Toolbar from "react-big-calendar/lib/Toolbar";
import StudentMenu from "./StudentMenu/StudentMenu";
import "react-big-calendar/lib/css/react-big-calendar.css";
import '../../App.css';
import Menu from "../App/_layouts/default/Menu/Menu";
import { MobileView, BrowserView, MobileOnlyView, TabletView } from "react-device-detect";
import {
  Container,
  UserOptionsContainer,
  customStyles,
  HeaderContainer,
  Logo,
  LogoContainer,
  LoggedUserContainer,
  LoggedUserName,
  StickyHeader,
  StickyBody,
  StickyBodyMobile,
  LogoWithUserContainer,
  SelectContainer,
  ButtonContainer,
  Row,
  Column,
  IconSwitch,
  Burger,
  IconCopy,
  ChapterTitle
} from "./calendar.styles";
import {
  getEvents,
  getStudentEventCount,
  getAllSchoolEvents,
  loadHolidays,
  createEvent,
  getStudentData,
} from "../../actions/schedulerActions";
import SivanPlusLogo from "../App/_layouts/default/Header/sivanLogo.png";
import { isMobileOnly, isTablet, isMobile } from "react-device-detect";
import { getStudents } from "../../actions/studentesActions";
import EventDayView from "./CalendarViews/EventDayView";
import EventBikeDayView from "./CalendarViews/EventBikeDayView";
import EventAgendaView from "./CalendarViews/EventAgendaView";
import EventMonthView from "./CalendarViews/EventMonthView";
import EventWorkWeekView from "./CalendarViews/EventWorkWeekView";
import EventWeekView from "./CalendarViews/EventWeekView";
import StudentBasicInfo from "./StudentBasicInfo/StudentBasicInfo";
import PrintCalendar from "./PrintCalendar/PrintCalendar";
import StudentSignature from "./StudentSignature/StudentSignature";
import { getUsers } from "../../actions/userActions";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/he";
import Select from "react-select";
import Button from "../UiComponents/Buttons/Default";
import SplitButton from "../UiComponents/Buttons/SplitButton"
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Toggle from "react-toggle";
import Input from "../UiComponents/Inputs/Default";
import { getVehicles } from "../../actions/vehicleActions";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import DriveEtaIcon from '@mui/icons-material/DriveEta';

import PrintIcon from '../../images/print.ico';
import AddIncomeIcon from '../../images/newcharge.ico';
import ReCalculateIcon from '../../images/recalculate.ico';
import CreateEventIcon from '../../images/addevent.ico';
import { EventType } from "../../constants/events";
import Icon from "../UiComponents/Icon";
import { Collapse } from "react-collapse";

const format = 'hh:mm';
const localizer = momentLocalizer(moment);
// const _oldFuntion = localizer.formats.dayHeaderFormat
// localizer.formats.agendaHeaderFormat = (_ref2, culture, local) => {
//   const result = _oldFuntion(_ref2, culture, local);
//   return result.split(' ', 2)[0];
// }
const messages = {
  allDay: "כל היום",
  previous: "הקודם",
  next: "הבא",
  today: "היום",
  month: "חודש",
  week: "שבוע",
  work_week: "שבוע עבודה",
  day: "יום",
  agenda: "אגנדה",
  date: "תאריך",
  time: "שעה",
  event: "אירוע",
  myweek: "יומי אופנועים",
};

const sbMenuList = ['myweek', 'agenda', 'week', 'month', 'day'];

class Calendara extends PureComponent {
  constructor(props) {
    super(props);
    this.studentProfile = null
    this.agentaColors = []
    this.bigCalendar = null
    this.format = null

    this.state = {
      dateText: "",
      isFoldMenu: isMobile ? true : false,
      isNewLessonModalOpen: false,
      isStudentMenuModalOpen: false,
      isErrorModalOpen: false,
      errorModalText: "",
      isEditLessonModalOpen: false,
      isNewChargeModalOpen: false,
      isPickupModalOpen: false,
      isNotesModalOpen: false,
      teacherOptions: [],
      selectedTeacher: [{ value: -999, label: "הכל" }],
      selectedVehicle: null,
      isEventStatusChangeModalOpen: false,
      isChangeTeacherModalOpen: false,
      isStudentSignatureModalOpen: false,
      isChangeVehicleModalOpen: false,
      blockText: "",
      isBlockActive: false,
      isChapterCollapsed: true,
      isPrintOpen: false,
      selectedEvent: null,
      defaultView: props.user.defaultView,
      currentDate: new Date(),
      students: [],
      vehicleOptions: [],
      chosenStudent: null,
      studentOptions: [],
      // isStudentInfoShowOpen: !isTablet,
      isStudentInfoShowOpen: false,
      views: {
        day: true,
        month: true,
        week: EventWorkWeekView,
        agenda: true,
      },
    };
  }

  CustomToolbar = (props) => {
    if (this.state.dateText != props.label) {
      this.setState({ dateText: props.label })
    }
    return (
      <div className='rbc-toolbar'>
        {/* <span className="rbc-btn-group"></span>
        <span className="rbc-toolbar-label">{props.label}</span> */}
      </div>
    );
  }  

  loadStudentProfile = async ({ studentId, textBookId, eventType }) => {
    // return this.props.getStudentData(studentId, textBookId);
    if (this.studentProfile === null || !(this.studentProfile.studentId == studentId && this.studentProfile.textBookId == textBookId && eventType == this.studentProfile.eventType)) {
      this.studentProfile = await this.props.getStudentData(studentId, textBookId, false, false, eventType)
    }
    return this.studentProfile
  }

  componentWillMount = () => {
    const availableViews = Object.keys(this.state.views);
    if (this.props.user.showDailyBikes) {
      this.setState({ views: { ...this.state.views, myweek: Day } });
      availableViews.push('myweek');
    }
    if (!availableViews.includes(this.state.defaultView)) {
      this.setState({ defaultView: "day" })
    }
    this.agentaColors = []
  };

  resetBoard = async () =>{
    window.location.reload();
  }

  componentDidMount = async () => {
    try {
      
    this.formats = {
      agendaHeaderFormat: ({ start, end }, culture, localizer) => {
        return localizer.format(start, 'dddd', culture);
      },
      dayHeaderFormat: (date, culture, localizer) => {
        return localizer.format(date, 'dddd', culture);
      },
    };
      const lsT = window.localStorage.getItem('selectedTeacher');
      const lsV = window.localStorage.getItem('selectedVehicle');

      if (lsT !== null && lsT !== undefined && lsT !== '') {
        console.log('set from ls - teacher');
        this.setState({
          selectedTeacher: JSON.parse(window.localStorage.getItem('selectedTeacher')),
          isTeacherInInput: true
        });
      }
      if (lsV !== null && lsV !== undefined && lsV !== '') {
        console.log('set from ls - car');
        this.setState({
          selectedVehicle: JSON.parse(window.localStorage.getItem('selectedVehicle')),
          isCarInInput: true
        });
      }
    } catch (err) {

    }

    if (this.props.isLoggedIn) {
      if (!this.props.isHolidaysLoaded) {
        await this.props.loadHolidays();
      }
      if (!this.props.isUsersLoaded) {
        await this.props.getUsers();
      }
      if (!this.props.isVehiclesLoaded) {
        await this.props.getVehicles();
      }
      const vehicleOptions = this.props.vehicles
        .filter((x) => x.active === 1)
        .map((x) => ({ value: x.id, label: x.description }));
      const teacherOptions = [
        { value: -999, label: "הכל" },
        ...this.props.users
          .filter(
            (x) => (x.userType === 1 || x.userType === 4) && x.isActive === 1
          )
          .map((x) => ({
            value: x.id,
            label: `${x.firstName} ${x.lastName}`,
          })),
      ];
      this.setState({
        teacherOptions,
        vehicleOptions,
      });
      if (this.props.user.userType !== 555) {
        const calendarUser = window.localStorage.getItem("calendarUser");
        if (calendarUser && JSON.parse(calendarUser) !== null) {
          const parsedCalendarUser = JSON.parse(calendarUser);
          try {
            const index = this.props.users.findIndex(
              (x) => x.id === parsedCalendarUser[0].value
            );
            if (index === -1) {
              await this.handleTeacherChange([
                {
                  value: this.props.session.user.id,
                  label: `${this.props.session.user.firstName} ${this.props.session.user.lastName}`,
                },
              ]);
            } else {
              const localStorageDate = window.localStorage.getItem("date");
              if (localStorageDate) {
                this.setState({ currentDate: new Date(localStorageDate) });
              }
              await this.handleTeacherChange(parsedCalendarUser);
            }
          } catch(err) {

          }
        } else {
          await this.handleTeacherChange([
            {
              value: this.props.session.user.id,
              label: `${this.props.session.user.firstName} ${this.props.session.user.lastName}`,
            },
          ]);
        }
      }
      if (!this.props.isStudentsLoaded) {
        await this.props.getStudents();
        this.setState({
          studentOptions: this.props.students.map((x) => ({
            value: x.studentId,
            label: `${x.studentId} - ${x.firstName} ${x.lastName}`,
          })),
        });
      } else {
        // console.log(this.props.students);
      }
    }
  };

  closeModal = (type) => this.setState({ [type]: false });
  openModal = (type, selectedEvent = null) =>
    this.setState({ [type]: true, selectedEvent });

  closeEditLessonModalOpen = () =>
    this.setState({ isEditLessonModalOpen: false });

  setEventColors = (eventType, eventStatus) => {
    const {
      fontLessonColor,
      lessonColor,
      doneLessonColor,
      fontDoneLessonColor,
      paidLessonColor,
      fontPaidLessonColor,
      canceledLessonColor,
      fontCanceledLessonColor,
      canceledAndPaidLessonColor,
      fontCanceledAndPaidLessonColor,
      testColor,
      fontTestColor,
      doneTestColor,
      fontDoneTestColor,
      paidTestColor,
      fontPaidTestColor,
      canceledTestColor,
      fontCanceledTestColor,
      canceledAndPaidTestColor,
      fontCanceledAndPaidTestColor,
      internalTestColor,
      fontInternalTestColor,
      doneInternalTestColor,
      fontDoneInternalTestColor,
      paidInternalTestColor,
      fontPaidInternalTestColor,
      canceledInternalTestColor,
      fontCanceledInternalTestColor,
      canceledAndPaidInternalTestColor,
      fontCanceledAndPaidInternalTestColor,
      meetingColor,
      fontMeetingColor,
      doneMeetingColor,
      fontDoneMeetingColor,
      canceledMeetingColor,
      fontCanceledMeetingColor,
    } = this.props.user;
    let color = "";
    let backgroundColor = "";
    if (eventType === 1) {
      const params = this.eventColors(
        lessonColor,
        fontLessonColor,
        doneLessonColor,
        fontDoneLessonColor,
        paidLessonColor,
        fontPaidLessonColor,
        canceledLessonColor,
        fontCanceledLessonColor,
        canceledAndPaidLessonColor,
        fontCanceledAndPaidLessonColor,
        eventStatus
      );

      backgroundColor = params.backgroundColor;
      color = params.color;
    } else if (eventType === 2) {
      const params = this.eventColors(
        testColor,
        fontTestColor,
        doneTestColor,
        fontDoneTestColor,
        paidTestColor,
        fontPaidTestColor,
        canceledTestColor,
        fontCanceledTestColor,
        canceledAndPaidTestColor,
        fontCanceledAndPaidTestColor,
        eventStatus
      );

      backgroundColor = params.backgroundColor;
      color = params.color;
    } else if (eventType === 3) {
      const params = this.eventColors(
        internalTestColor,
        fontInternalTestColor,
        doneInternalTestColor,
        fontDoneInternalTestColor,
        paidInternalTestColor,
        fontPaidInternalTestColor,
        canceledInternalTestColor,
        fontCanceledInternalTestColor,
        canceledAndPaidInternalTestColor,
        fontCanceledAndPaidInternalTestColor,
        eventStatus
      );

      backgroundColor = params.backgroundColor;
      color = params.color;
    } else if (eventType === 4) {
      const params = this.eventColors(
        meetingColor,
        fontMeetingColor,
        doneMeetingColor,
        fontDoneMeetingColor,
        doneMeetingColor,
        fontDoneMeetingColor,
        canceledMeetingColor,
        fontCanceledMeetingColor,
        canceledMeetingColor,
        fontCanceledMeetingColor,
        eventStatus
      );
      backgroundColor = params.backgroundColor;
      color = params.color;
    }

    return { backgroundColor, color };
  };

  eventColors = (
    orderBackground,
    orderFontColor,
    doneBackground,
    doneFontColor,
    paidBackground,
    paidFontColor,
    canceledBackground,
    canceledFontColor,
    canceledAndPaidBackground,
    canceledAndPaidPaidFontColor,
    eventStatus
  ) => {
    if (eventStatus === 0) {
      return { backgroundColor: orderBackground, color: orderFontColor };
    } else if (eventStatus === 1) {
      return { backgroundColor: doneBackground, color: doneFontColor };
    } else if (eventStatus === 2) {
      return { backgroundColor: paidBackground, color: paidFontColor };
    } else if (eventStatus === 3) {
      return { backgroundColor: canceledBackground, color: canceledFontColor };
    } else if (eventStatus === 4) {
      return {
        backgroundColor: canceledAndPaidBackground,
        color: canceledAndPaidPaidFontColor,
      };
    }
  };

  eventStyleGetter = (event, start, end, isSelected) => {
    const b = this.setEventColors(event.eventType, event.eventStatus);
    let agStyle = {}
    if (this.state.defaultView === 'agenda') {
      agStyle = this.agendaViewStyle(event)
    }
    var style = {
      borderRadius: "0px",
      border: "0px",
      display: "block",
      borderLeft: "3px solid",
      borderTop: "1px solid",
      borderRight: "1px solid",
      ...b,
      ...agStyle,
    };
    return {
      style: style,
    };
  };

  timeView = ({event, day, label}) => {
    if (event.eventType == undefined || this.state.defaultView !== 'agenda' || !label) return label;

    return label.split(' – ', 2)[0]
  }
  
  agendaViewStyle = (event) => {
    const startTime = moment(event.start).format(format);
    let eventType = null;
    for (var t in EventType) {
      if (EventType[t] == event.eventType) {
        eventType = t;
        break;
      };
    }
    const calendarTimeColors = this.props.user.settingth_params ? this.props.user.settingth_params.calendarTimeColors : {}
    if (!calendarTimeColors[eventType]) return {};
    let agetnaColors = this.agentaColors.find((e) => e.time == startTime)
    const result = {}
    if (!agetnaColors) {
      let index = this.agentaColors.length > 0 ? this.agentaColors[this.agentaColors.length -1].index + 1 : 0;
      if (index >= calendarTimeColors[eventType].length) index = 0;
      this.agentaColors.push({
        time: startTime,
        count: 0,
        index: index,
      })
      agetnaColors = this.agentaColors.find((e) => e.time == startTime)
    }
    
    const _color = calendarTimeColors[eventType][agetnaColors.index]
    agetnaColors.count ++;
    result.backgroundColor = _color.background
    result.color = _color.font
    return result
  }

  handleTeacherChange = async (selectedTeacher) => {
    this.agentaColors = [];
    this.setState({ selectedTeacher });
    if (selectedTeacher) {
      window.localStorage.setItem(
        "calendarUser",
        JSON.stringify(selectedTeacher)
      );
      if (this.state.isTeacherInInput) {
        console.log('set teacher in ls');
        window.localStorage.setItem('selectedTeacher', JSON.stringify(selectedTeacher));
      }
      if (
        !selectedTeacher ||
        selectedTeacher.findIndex((x) => x.value === -999) !== -1
      ) {
        await this.props.getAllSchoolEvents();
      } else {
        await this.props.getEvents(selectedTeacher.map((x) => x.value));
      }
    }
  };

  handleVehicleChange = async (selectedVehicle) => {
    this.agentaColors = [];
    this.setState({ selectedVehicle }, () => {
      if (this.state.isCarInInput) {
        console.log('set vehicle in ls');
        window.localStorage.setItem('selectedVehicle', JSON.stringify(selectedVehicle));
      }
    });
  };

  createBlockEvent = async (slot) => {
    // this.setState({chosenStudent: '', chosenStudentId: '', selectedTeacher: { value: -999, label: "הכל" }}, () => {
    //
    // }); // reset



    const { blockText, selectedTeacher, teacherOptions } = this.state;
    const isAllIndex = selectedTeacher.findIndex((x) => x.value === -999);
    let teacherList = selectedTeacher;
    if (isAllIndex !== -1) {
      teacherList = teacherOptions;
    }
    const eventsToCreate = [];
    for (let index = 0; index < teacherList.length; index++) {
      const element = teacherList[index];
      eventsToCreate.push(
        this.props.createEvent({
          notes: blockText,
          eventType: { value: 4, label: "פגישה" },
          updateUsername: `${this.props.user.firstName} ${this.props.user.lastName}`,
          teacherId: element.value,
          pickupLocationId: { value: 999, label: "ללא" },
          start: slot.start,
          end: slot.end,
        })
      );
    }

    await Promise.all(eventsToCreate);

    // this.setState({
    //   selectedTeacher: { value: -999, label: "הכל" },
    //   chosenStudent: '', chosenStudentId: '',
    //   selectedVehicle: null
    // });
    
    if (isAllIndex !== -1) {
      await this.props.getAllSchoolEvents();
    } else {
      await this.props.getEvents(teacherList.map((x) => x.value));
    }
  };

  onFinish = () => {
    // this.resetBoard();
    // alert(1)
    //window.location.reload();
    // window.location.href='?selectedTeacher='+this.state.selectedTeacher+'&selectedVehicle='+this.state.selectedVehicle;
  }

  onNavigate = (date) => {
    this.agentaColors = [];
    this.setState({ currentDate: date });
    window.localStorage.setItem("date", date);
  }

  onView = (view) => { 
    this.setState({ defaultView: view });
  }

  async clickStudent(value) {
    this.agentaColors = [];
    let showProfile = false;
    if (value) {
      showProfile = this.getFilteredEvents(false).filter((v) => {
        return value.value == v.studentId && v.isShow
      }).length === 0
    } else {
      this.setState({ isStudentInfoShowOpen: false })
    }
    if (!value || !this.state.chosenStudent || this.state.chosenStudent.value != value.value) {
      this.setState({ chosenStudent: value })
      if (showProfile) this.setState({ isStudentInfoShowOpen: true })
      return;
    }
    this.setState({ chosenStudent: value })
    if (showProfile) this.setState({ isStudentInfoShowOpen: true })
    if (!value || !value.event) return
    let isClose = false;
    isClose = this.studentProfile !== null && 
      this.studentProfile.studentId == value.value && 
      value.event.textBookId == this.studentProfile.textBookId &&
      value.event.eventType == this.studentProfile.eventType;
    if (isClose && this.state.isStudentInfoShowOpen) {
      this.setState({ isStudentInfoShowOpen: false })
    } else {
      this.setState({ isStudentInfoShowOpen: !this.state.isStudentInfoShowOpen })
    }
  }

  getFilteredEvents(resetShow = true) {
    const { events, holidays } = this.props;
    let filteredEvents = [...events];
    if (this.state.selectedVehicle !== null && this.state.selectedVehicle.length > 0) {
      filteredEvents = filteredEvents.filter(
        (x) =>
        this.state.selectedVehicle
            .map((x) => x.value.toString())
            .indexOf(x.vehicleId) !== -1
      );
    }
    let result = [...filteredEvents, ...holidays];
      if (this.state.defaultView === 'agenda') {
        const start = moment().locale('he').set('date', this.state.currentDate.getDate()).set('hour', 0).set('minute', 0)
        const end = moment().locale("he").set('date', this.state.currentDate.getDate()).set('hour', 23).set('minute', 59)
        result = result.filter((v) => moment(v.start).isBetween(start, end))
        const allDay = result.find((v) => v.allDay)
        if (allDay) {
          allDay.start = moment(allDay.start).locale("he").set('hour', 0)
        }
      }
    if (resetShow) {
      return result.map((elem) => {
        elem.isShow = false;
        return elem;
      })
    }
    return result;
  }

  CalendarView() {
    let height = !isMobile && "82.9vh"
    if (isTablet) height = '71.5vh'
    const {
      defaultView,
      currentDate,
      chosenStudent,
      views,
    } = this.state;
    const { user } = this.props;
    const filteredEvents = this.getFilteredEvents();
    return <Calendar
      formats={this.formats}
      popup
      ref={c => { this.bigCalendar = c } }
      views={views}
      messages={messages}
      localizer={localizer}
      culture="he"
      showAllEvents={false}
      components={{
        toolbar : this.CustomToolbar,
        time: this.timeView,
        myweek: {
          event: (props) => {
            props.event.isShow = true;
            return !props.event.allDay ? (
              <EventBikeDayView
                event={props.event}
                isMargin={true}
                chosenStudentId={chosenStudent?.value}
                step={user.calendarSpacingInMinutes}
                loadStudentProfile = {this.loadStudentProfile}
                openPickupModal={(eventId) =>
                  this.openModal("isPickupModalOpen", { eventId })
                }
                openEventStatusChangeModal={(event) =>
                  this.openModal("isEventStatusChangeModalOpen", event)
                }
                openStudentMenuModal={(event) =>
                  this.openModal("isStudentMenuModalOpen", event)
                }
                openNotesChangeModal={(eventId) =>
                  this.openModal("isNotesModalOpen", { eventId })
                }
                getStudentEventCount={this.props.getStudentEventCount}
                onStudentClick={(value) =>
                  this.clickStudent(value)
                }
              />
            ) : (
              <div style={{ textAlign: "center" }}>{props.event.title}</div>
            );
          },
        },
        agenda: {
          date: undefined,
          time: (props) => {
            if (props.event.id === undefined) return props.label

            return <span onClick={() => this.openModal('isNewLessonModalOpen', {...props.event, slots:[props.event.start, props.event.end]})}>{props.label.split(' ')[0]}</span>;
          },
          event: (props) => {
            props.event.isShow = true;
            // props.event.style = {backgroundColor: "#555"}
            return !props.event.allDay ? (
              <EventAgendaView
                event={props.event}
                // isMargin={true}
                chosenStudentId={chosenStudent?.value}
                step={user.calendarSpacingInMinutes}
                loadStudentProfile = {this.loadStudentProfile}
                openPickupModal={(eventId) =>
                  this.openModal("isPickupModalOpen", { eventId })
                }
                openSignatureModal={(eventId) =>
                  this.openModal("isStudentSignatureModalOpen", { eventId })
                }
                openEventStatusChangeModal={(event) =>
                  this.openModal("isEventStatusChangeModalOpen", event)
                }
                openStudentMenuModal={(event) =>
                  this.openModal("isStudentMenuModalOpen", event)
                }
                openNotesChangeModal={(eventId) =>
                  this.openModal("isNotesModalOpen", { eventId })
                }
                getStudentEventCount={this.props.getStudentEventCount}
                onStudentClick={(value) =>
                  this.clickStudent(value)
                }
                newLesson={(event) => this.openModal("isNewLessonModalOpen", event)}
              />
            ) : (
              <div style={{ textAlign: "center" }}>{props.event.title}</div>
            );
          },
        },
        day: {
          event: (props) => {
            props.event.isShow = true;
            return !props.event.allDay ? (
              <EventDayView
                event={props.event}
                chosenStudentId={chosenStudent?.value}
                step={user.calendarSpacingInMinutes}
                loadStudentProfile = {this.loadStudentProfile}
                openPickupModal={(eventId) =>
                  this.openModal("isPickupModalOpen", { eventId })
                }
                openEventStatusChangeModal={(event) =>
                  this.openModal("isEventStatusChangeModalOpen", event)
                }
                openTeacherChange={(event) =>
                  this.openModal("isChangeTeacherModalOpen", event)
                }
                openVehicleChange={(event) =>
                  this.openModal("isChangeVehicleModalOpen", event)
                }
                openNotesChangeModal={(eventId) =>
                  this.openModal("isNotesModalOpen", { eventId })
                }
                openStudentMenuModal={(event) =>
                  this.openModal("isStudentMenuModalOpen", event)
                }
                getStudentEventCount={this.props.getStudentEventCount}
                onStudentClick={(value) =>
                  this.clickStudent(value)
                }
              />
            ) : (
              <div style={{ textAlign: "center" }}>{props.event.title}</div>
            );
          }
        },
        month: {
          event: (props) => {
            props.event.isShow = true;
            return !props.event.allDay ? (
              <EventMonthView
                event={props.event}
                chosenStudentId={chosenStudent?.value}
                step={user.calendarSpacingInMinutes}
                getStudentEventCount={this.props.getStudentEventCount}
                loadStudentProfile = {this.loadStudentProfile}
              />
            ) : (
              <div style={{ textAlign: "center" }}>{props.event.title}</div>
            );
          },
        },
        week: {
          event: (props) => {
            props.event.isShow = true;
            return !props.event.allDay ? (
              <EventWeekView
                chosenStudentId={chosenStudent?.value}
                isBlockActive={this.state.isBlockActive}
                event={props.event}
                step={user.calendarSpacingInMinutes}
                loadStudentProfile = {this.loadStudentProfile}
                openNotesChangeModal={(eventId) =>
                  this.openModal("isNotesModalOpen", { eventId })
                }
                openEventStatusChangeModal={(event) =>
                  this.openModal("isEventStatusChangeModalOpen", event)
                }
                onStudentClick={(value) =>
                  this.clickStudent(value)
                }
              />
            ) : (
              <div style={{ textAlign: "center" }}>{props.event.title}</div>
            );
          },
        },
      }}
      events={filteredEvents}
      step={user.calendarSpacingInMinutes}
      timeslots={1}
      selectable
      startAccessor="start"
      endAccessor="end"
      rtl={true}
      slotEventOverlap={false}
      onNavigate={this.onNavigate}
      view={defaultView}
      onView={this.onView}
      date={currentDate}
      length={ 1 }
      min={
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date(user.calendarStartTime).getHours()
        )
      }
      max={
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date(user.calendarEndTime).getHours()
        )
      }
      eventPropGetter={this.eventStyleGetter}
      style={{ height, flexGrow: "1" }}
      onSelectSlot={(e) =>
        typeof e.resourceId !== "undefined" &&
        (this.state.isBlockActive
          ? this.createBlockEvent(e)
          : this.openModal("isNewLessonModalOpen", e))
      }
    />
  }

  ButtonsView () {
    const {
      currentDate,
      defaultView,
      isBlockActive,
      blockText,
      dateText,
    } = this.state;
    const { events, user } = this.props;    
    return (
      <Column>
        <Row className="row-select">
          <Button className="button-navigation"
            text="הבא"
            marginRight="0.3"
            onClick={ () => this.bigCalendar.handleNavigate('NEXT') }
          />
          <Button className="button-navigation"
            text="הקודם"
            marginRight="0.3"
            onClick={ () => this.bigCalendar.handleNavigate('PREV') }
          />
          <Button className="button-navigation"
            text="היום"
            marginRight="0.3"
            onClick={ () => this.bigCalendar.handleNavigate('TODAY') }
          />
          <input type="text" 
            className="calendar-date-label"
            value={ dateText }
            disabled
          /> 
          <input type="date" 
            style={{ flexGrow: "1" }} 
            value={ moment(currentDate).local('he').format('YYYY-MM-DD') } 
            onChange={ (e) => { this.onNavigate(new Date(e.target.value)) }}
          />
        </Row>
      
        <Row className="row-select">
          {this.props.user.showDailyBikes ? (
            <Button className="button-navigation"
              text="יומי אופנועים"
              marginRight="0.3"
              isActive={ defaultView === 'myweek' }
              onClick={ () => this.onView('myweek') }
            />
          ) : null}
          <Button className="button-navigation"
            text="אגנדה"
            marginRight="0.3"
            isActive={ defaultView === 'agenda' }
            onClick={ () => this.onView('agenda') }
          />
          <Button className="button-navigation"
            text="שבוע"
            marginRight="0.3"
            isActive={ defaultView === 'week' }
            onClick={ () => this.onView('week') }
          />
          <Button className="button-navigation"
            text="חודש"
            marginRight="0.3"
            isActive={ defaultView === 'month' }
            onClick={ () => this.onView('month') }
          />
          <Button className="button-navigation"
            text="יום"
            isActive={ defaultView === 'day' }
            onClick={ () => this.onView('day') }
          />
        </Row>

        <Row className="row-select">
          {user.createStudentIncome ? (
            <Button className="button-action"
              text={ <i class="fa-lg fa-solid fa-money-bill"></i> }
              alt="מסמך כספי"
              marginRight="0.3"
              onClick={ () => this.setState({ isNewChargeModalOpen: true }) }
            />
          ) : null }
          <Button className="button-action"
            text={ <i class="fa-lg fa-solid fa-folder"></i> }
            alt="יצירת אירוע"
            marginRight="0.3"
            onClick={ () =>
              this.openModal("isNewLessonModalOpen", {
                start: new Date(),
                slots: [{}, {}],
              })
            }
          />
          <Button className="button-action"
            text={ <i class="fa-lg fa-solid fa-print"></i> }
            alt="הדפסת יומן"
            marginRight="0.3"
            onClick={ () =>
              this.openModal("isPrintOpen", {
                events,
              })
            }
          />

          {user.blockEvents ? (
            <>
              <div
                style={{
                  width: "9vw",
                  display: "flex",
                  alignItems: "center",
                  marginRight: "0.5vw",
                  marginLeft: "0.5vw",
                  flexDirection: "column",
                }}
              >
                <label htmlFor="cheese-status-block" style={{ margin: 0 }}>
                  חסימות
                </label>
                <Toggle
                  id="cheese-status-block"
                  defaultChecked={false}
                  onChange={ () =>
                    this.setState({ isBlockActive: !isBlockActive })
                  }
                />
              </div>
              <Input
                value={blockText}
                onChange={ (e) => this.setState({ blockText: e.target.value })}
                placeholder="סיבת חסימה"
              />
            </>
          ) : null}   
        </Row>
      </Column>     
    );
  }

  SelectorsView () {
    const {
      chosenStudent,
      selectedTeacher,
      teacherOptions,
      selectedVehicle,
      vehicleOptions,
      isTeacherInInput,
      isCarInInput,
    } = this.state;
    const { user, students } = this.props;
    

    const copyStydent = () => {
      navigator.clipboard.writeText(this.state.chosenStudent.label);
    }

    const copyTeacher = () => {
      navigator.clipboard.writeText(this.state.selectedTeacher.map((v) => v.label).join(', '));
    }

    const copyVehicle = () => {
      navigator.clipboard.writeText(this.state.selectedVehicle.map((v) => v.label).join(', '));
    }
    return (
      <Column>
        <Row className="row-select">
          { students.length > 0 && (
            <>
              <Toggle className="select-toggle"
                id="cheese-status-student"
                defaultChecked={false}
              />
              <Select
                isClearable
                options={ students
                  .filter((x) => x.active === 1)
                  .map((x) => ({
                    value: x.studentId,
                    label: `${x.studentId} - ${x.firstName} ${x.lastName}`,
                  }))}
                value={chosenStudent}
                placeholder="בחר תלמיד"
                styles={customStyles}
                isRtl={true}
                onChange={(value) => this.clickStudent(value) }
                style={{
                  minWidth: '100%'
                }}
              />
              {this.state.chosenStudent && <Icon onClick={copyStydent} />}
            </> 
          )}
        </Row>
    
        { user.userType === 1 ? null : (
          <>
            <Row className="row-select">
              <Toggle className="select-toggle"
                id="cheese-status-teacher"
                defaultChecked={false}
                checked={isTeacherInInput}
                value={isTeacherInInput}
                onChange={() =>
                  this.setState({ isTeacherInInput: isTeacherInInput ? false : true }, () => {
                    if (!isTeacherInInput) window.localStorage.removeItem('selectedTeacher');
                    else {
                      if (isTeacherInInput) {
                        console.log('set teacher in ls');
                        window.localStorage.setItem('selectedTeacher', JSON.stringify(selectedTeacher));
                      }
                    }
                  })
                }
              />                      
              <Select
                options={teacherOptions}
                isMulti
                value={selectedTeacher}
                placeholder="מורה"
                styles={customStyles}
                isRtl={true}
                onChange={this.handleTeacherChange}
                style={{
                  minWidth: '100%'
                }}
              />
              {this.state.selectedTeacher && this.state.selectedTeacher.length > 0 && <Icon onClick={copyTeacher} />}
            </Row>
            <Row className="row-select">
              <Toggle className="select-toggle"
                id="cheese-status-vehicle"
                defaultChecked={false}
                checked={isCarInInput}
                value={isCarInInput}
                onChange={() =>
                  this.setState({ isCarInInput: isCarInInput ? false : true }, () => {
                    if (!isCarInInput) window.localStorage.removeItem('selectedVehicle');
                    else {
                      if (isCarInInput) {
                        console.log('set vehicle in ls');
                        window.localStorage.setItem('selectedVehicle', JSON.stringify(selectedVehicle));
                      }
                    }
                  })
                }
              />
              <Select
                options={vehicleOptions}
                isMulti
                value={selectedVehicle}
                placeholder="רכב"
                styles={customStyles}
                isRtl={true}
                onChange={this.handleVehicleChange}
                style={{
                  minWidth: '100%'
                }}
              />
              {this.state.selectedVehicle && this.state.selectedVehicle.length > 0 && <Icon onClick={copyVehicle} />}
            </Row>
          </>  
        )}  
      </Column>
    );
  }

  ActionsView () {
    const {
      isNewLessonModalOpen,
      isNotesModalOpen,
      isErrorModalOpen,
      isPickupModalOpen,
      isStudentMenuModalOpen,
      isStudentSignatureModalOpen,
      isEventStatusChangeModalOpen,
      isChangeTeacherModalOpen, 
      isChangeVehicleModalOpen,
      isNewChargeModalOpen,
      isPrintOpen,
      isStudentInfoShowOpen,
      isTeacherInInput,
      isCarInInput,
      chosenStudent,
      selectedTeacher,
      selectedVehicle,
      selectedEvent,
      currentDate,
      errorModalText,
    } = this.state;
    const { events, user } = this.props;    
    return (
      <>
        {isNewLessonModalOpen && (
          <NewLesson
            isNewAgreementModalOpen={isNewLessonModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isNewLessonModalOpen")
            }
            onFinish={() => this.onFinish()}
            selectedEvent={selectedEvent}
            step={user.eventTime}
            studentFromCalendar={chosenStudent?.value}
            selectedTeacher={isTeacherInInput ? selectedTeacher : []}
            selectedVehicle={isCarInInput ? selectedVehicle : []}
          />
        )}
        {isNotesModalOpen && (
          <NoteChange
            isNewAgreementModalOpen={isNotesModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isNotesModalOpen")
            }
            selectedEvent={selectedEvent}
          />
        )}
        {isErrorModalOpen && (
          <ErrorModal
            modalIsOpen={isErrorModalOpen}
            closeModal={() => this.closeModal("isErrorModalOpen")}
            text={errorModalText}
          />
        )}
        {isPickupModalOpen && (
          <UpdatePickupLocation
            isNewAgreementModalOpen={isPickupModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isPickupModalOpen")
            }
            selectedEvent={selectedEvent}
            step={user.calendarSpacingInMinutes}
          />
        )}
        {isStudentMenuModalOpen && (
          <StudentMenu
            isNewAgreementModalOpen={isStudentMenuModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isStudentMenuModalOpen")
            }
            selectedEvent={selectedEvent}
          />
        )}
        {isStudentSignatureModalOpen && (
          <StudentSignature
            isNewAgreementModalOpen={isStudentSignatureModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isStudentSignatureModalOpen")
            }
            selectedEvent={selectedEvent}
          />
        )}
        {isEventStatusChangeModalOpen && (
          <EventStatusChange
            isNewAgreementModalOpen={isEventStatusChangeModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isEventStatusChangeModalOpen")
            }
            selectedEvent={selectedEvent}
            step={user.calendarSpacingInMinutes}
          />
        )}
        {isChangeTeacherModalOpen && (
          <TeacherChange
            isNewAgreementModalOpen={isChangeTeacherModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isChangeTeacherModalOpen")
            }
            selectedEvent={selectedEvent}
          />
        )}
        {isChangeVehicleModalOpen && (
          <VehicleChange
            isNewAgreementModalOpen={isChangeVehicleModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isChangeVehicleModalOpen")
            }
            selectedEvent={selectedEvent}
          />
        )}
        {isNewChargeModalOpen && (
          <NewCharge
            isNewAgreementModalOpen={isNewChargeModalOpen}
            setNewAgreementModalIsOpen={() =>
              this.closeModal("isNewChargeModalOpen")
            }
            studentFromCalendar={chosenStudent?.value}
          />
        )}
        {isPrintOpen && (
          <PrintCalendar
            isNewAgreementModalOpen={isPrintOpen}
            setNewAgreementModalIsOpen={() => this.closeModal("isPrintOpen")}
            events={events}
            teachers={selectedTeacher}
            currentDate={currentDate}
          />
        )}
      </>
    )}

  render() {
    const {
      isFoldMenu,
      currentDate,
      chosenStudent,
      isStudentInfoShowOpen,
      dateText,
      defaultView,
      isChapterCollapsed
    } = this.state;
    const { events, user } = this.props;
    // const filteredEvents = this.getFilteredEvents();
    return (
      <>
      <BrowserView style={{display: "flex", width: "100%"}}>
        <Container style={{width: "100%"}}>
          {/**/}
          <HeaderContainer isRtl={true}>
            <LogoWithUserContainer>
              <Logo src={SivanPlusLogo} alt="Logo" />                  
              <Row>
                <Column alignItems="flex-end">
                  <span style={{ fontSize: "18pt" }}>-{user.firstName} {user.lastName}</span>
                  <span style={{ fontSize: "18pt" }}>{user.schoolName}</span>
                </Column>
                <span style={{ paddingLeft: "10px" }}>
                  <IconSwitch
                    className={isFoldMenu ?"fa-3x fas fa-fw fa-list-alt" :"fa-3x fas fa-fw fa-window-close"}
                    isFoldMenu={isFoldMenu}
                    onClick={() => this.setState({ isFoldMenu: !isFoldMenu})}
                  ></IconSwitch>
                </span>
              </Row>
            </LogoWithUserContainer>
            <SelectContainer>
              { this.SelectorsView() }
            </SelectContainer>
            <ButtonContainer>
              { this.ButtonsView() }
            </ButtonContainer>
          </HeaderContainer>
          {/**/}
          <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
            { this.CalendarView() }
            {chosenStudent !== null && isStudentInfoShowOpen && (
              <StudentBasicInfo
                forEvent={chosenStudent.event}
                studentId={chosenStudent.value}
                loadStudentProfile={this.loadStudentProfile}
                close={() => this.closeModal("isStudentInfoShowOpen")}
                handleTeacherChange={this.handleTeacherChange}
                dateChange={(date) => this.setState({ currentDate: date })}
                openStudentMenuModal={(event) =>
                  this.openModal("isStudentMenuModalOpen", event)
                }
              />
            )}
            <div style={{ paddingRight: "10px" }}>
              <Menu isCalendarpPage={true} isFoldMenu={isFoldMenu} />
            </div>
          </div>
          
          { this.ActionsView() }
        </Container>
      </BrowserView>

      <MobileOnlyView>
        <Container>
          <StickyHeader isRtl={true}>
            <UserOptionsContainer> 
              <Row style={{ paddingBottom: "2px" }}>
                {user.createStudentIncome ? (
                  <Button className="button-action"
                    text={ <i class="fa-lg fa-solid fa-money-bill"></i> }
                    alt="מסמך כספי"
                    marginRight="2"
                    onClick={ () => this.setState({ isNewChargeModalOpen: true }) }
                  />
                ) : null }
                <Button className="button-action"
                  text={ <i class="fa-lg fa-solid fa-folder"></i> }
                  alt="יצירת אירוע"
                  marginRight="2"
                  onClick={ () =>
                    this.openModal("isNewLessonModalOpen", {
                      start: new Date(),
                      slots: [{}, {}],
                    })
                  }
                />
                <Button className="button-action"
                  text={ <i class="fa-lg fa-solid fa-print"></i> }
                  alt="הדפסת יומן"
                  marginRight="2"
                  onClick={ () =>
                    this.openModal("isPrintOpen", {
                      events,
                    })
                  }
                />    
                <Logo src={SivanPlusLogo} alt="Logo" style={{ paddingRight: "15px" }}/>

                <span style={{ paddingLeft: "10px", paddingRight: "2px" }}>
                  <IconSwitch
                    className={isFoldMenu ?"fa-3x fas fa-fw fa-list-alt" :"fa-3x fas fa-fw fa-window-close"}
                    isFoldMenu={isFoldMenu}
                    onClick={() => this.setState({ isFoldMenu: !isFoldMenu})}
                  ></IconSwitch>
                </span>

              </Row>
              
              <ChapterTitle dir="auto">
                לְסַנֵן
                <i
                  className={`fa fa-${isChapterCollapsed ? "minus" : "plus"}-square-o`}
                  aria-hidden="true"
                  onClick={() => this.setState({ isChapterCollapsed: !isChapterCollapsed })}
                />
              </ChapterTitle>
              <Collapse
                isOpened={isChapterCollapsed}
                style={{ width: "40%", display: "flex" }}
              >
              { this.SelectorsView() }
              {user.blockEvents ? (
                <Row className="row-select">
                  <div className="select-toggle"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <label htmlFor="cheese-status-block" style={{ margin: 0 }}>
                      חסימות
                    </label>
                    <Toggle 
                      id="cheese-status-block"
                      defaultChecked={false}
                      onChange={ () =>
                        this.setState({ isBlockActive: !this.state.isBlockActive })
                      }
                    />
                  </div>
                  <Input
                    value={this.state.blockText}
                    onChange={ (e) => this.setState({ blockText: e.target.value })}
                    placeholder="סיבת חסימה"
                  />
                </Row>
              ) : null}
              <Row className="row-select">
                <input type="text" 
                  className="calendar-date-label"
                  value={ dateText }
                  disabled
                />
                <input type="date" 
                  style={{ flexGrow: "1" }} 
                  value={ moment(currentDate).local('he').format('YYYY-MM-DD') } 
                  onChange={ (e) => { this.onNavigate(new Date(e.target.value)) }}
                />
              </Row>
              <Row className="row-select" justifyContent="space-between">
                <SplitButton
                  className="split-button-navigation"
                  options={[ "הבא", "הקודם", "היום" ]}
                  onClick={[
                    () => this.bigCalendar.handleNavigate('NEXT'),
                    () => this.bigCalendar.handleNavigate('PREV'),
                    () => this.bigCalendar.handleNavigate('TODAY')
                  ]}
                />
                {this.props.user.showDailyBikes ?
                  ( <SplitButton
                      className="split-button-navigation"
                      defaultIndex = { sbMenuList.indexOf(defaultView) }
                      options={[ "יומי אופנועים", "אגנדה", "שבוע", "חודש",  "יום" ]}
                      onClick={[
                        () => this.onView('myweek'),
                        () => this.onView('agenda'),
                        () => this.onView('week'),
                        () => this.onView('month'),
                        () => this.onView('day')
                      ]}
                    />
                  ) : (
                    <SplitButton
                      className="split-button-navigation"
                      defaultIndex = { sbMenuList.indexOf(defaultView) - 1}
                      options={[ "אגנדה", "שבוע", "חודש",  "יום" ]}
                      onClick={[
                        () => this.onView('agenda'),
                        () => this.onView('week'),
                        () => this.onView('month'),
                        () => this.onView('day')
                      ]}
                    />
                  )
                }
                
              </Row></Collapse>
            </UserOptionsContainer>
          </StickyHeader>
          <StickyBodyMobile style={{ paddingTop: isChapterCollapsed ? "330px" : "70px"  }}>
            
            <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
              { this.CalendarView() }
              <Burger>  
                <div class="burger-menu-wrap">
                  <div class="burger-menu">
                    <Menu isCalendarpPage={true} isFoldMenu={isFoldMenu} />
                  </div>      
                </div>    
              </Burger>

              {isMobileOnly && chosenStudent !== null && isStudentInfoShowOpen && (

                <Burger zIndex={"1000"}>  
                <div class="burger-menu-wrap" style={{ top: "95px" }}>
                  <div class="burger-menu">
                    <StudentBasicInfo
                      forEvent={chosenStudent.event}
                      studentId={chosenStudent.value}
                      loadStudentProfile={this.loadStudentProfile}
                      close={() => this.closeModal("isStudentInfoShowOpen")}
                      handleTeacherChange={this.handleTeacherChange}
                      dateChange={(date) => this.setState({ currentDate: date })}
                      openStudentMenuModal={(event) =>
                        this.openModal("isStudentMenuModalOpen", event)
                      }
                    />
                  </div>      
                </div>    
              </Burger>
              )}


            </div>
          </StickyBodyMobile>
          { this.ActionsView() }
        </Container>
      </MobileOnlyView>

      <TabletView style={{display: "flex", width: "100%"}}>
        <Container style={{width: "100%"}}>
          <HeaderContainer isRtl={true}>
            <Column>
              <Row>
                <Column >
                    { this.SelectorsView() }
                </Column>
                <Column alignItems="flex-end" style={{ width: "50vw" }}>
                  <LogoWithUserContainer>
                    <Logo src={SivanPlusLogo} alt="Logo" />        
                    &nbsp;&nbsp;
                    <Row>
                      <Column alignItems="flex-end">
                        <span style={{ fontSize: "15pt" }}>-{user.firstName} {user.lastName}</span>
                        <span style={{ fontSize: "15pt" }}>{user.schoolName}</span>
                      </Column>
                      <span style={{ paddingLeft: "10px" }}>
                        <IconSwitch
                          className={isFoldMenu ?"fa-3x fas fa-fw fa-list-alt" :"fa-3x fas fa-fw fa-window-close"}
                          isFoldMenu={isFoldMenu}
                          onClick={() => this.setState({ isFoldMenu: !isFoldMenu})}
                        ></IconSwitch>
                      </span>
                    </Row>
                  </LogoWithUserContainer>
                </Column>
              </Row>
              <ButtonContainer>
                { this.ButtonsView() }
              </ButtonContainer>
            </Column>
          </HeaderContainer>

          <div style={{ display: "flex", flexDirection: "row", width: "100%"  }}>
            { this.CalendarView() }

            {chosenStudent !== null && isStudentInfoShowOpen && (
              <StudentBasicInfo
                forEvent={chosenStudent.event}
                studentId={chosenStudent.value}
                loadStudentProfile={this.loadStudentProfile}
                close={() => this.closeModal("isStudentInfoShowOpen")}
                handleTeacherChange={this.handleTeacherChange}
                dateChange={(date) => this.setState({ currentDate: date })}
                openStudentMenuModal={(event) =>
                  this.openModal("isStudentMenuModalOpen", event)
                }
              />
            )}

            <Burger>  
              <div class="burger-menu-wrap">
                <div class="burger-menu">
                  <Menu isCalendarpPage={true} isFoldMenu={isFoldMenu} />
                </div>      
              </div>    
            </Burger>
          </div>
          { this.ActionsView() }

        </Container>
      </TabletView>
      </>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    isLoggedIn: state.session.isLoggedIn,
    userId: state.session.user.id,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    showDailyBikes: state.session.user.showDailyBikes,
    events: state.CalendarReducer.events,
    holidays: state.CalendarReducer.holidays,
    isHolidaysLoaded: state.CalendarReducer.isHolidaysLoaded,
    isUsersLoaded: state.UsersReducer.isLoaded,
    users: state.UsersReducer.users,
    isStudentsLoaded: state.StudentsReducer.isLoaded,
    user: state.session.user,
    students: state.StudentsReducer.students,
  };
}

export default connect(mapStateToProps, {
  getUsers,
  getEvents,
  getStudentEventCount,
  getAllSchoolEvents,
  loadHolidays,
  getStudents,
  createEvent,
  getVehicles,
  getStudentData,
})(Calendara);
