import React, { useState, useEffect } from "react";
import ErrorModal from "../../Modal/ErrorModal";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change, reset } from "redux-form";
import validate from "./validate";
import { useTranslation } from "react-i18next";
import i18n from "../../../lib/i18n/i18n";
import SivanModal from "../../Modal/NewModal";
import { Body, Section, Row } from "./newCharge.styles";
import { getVehicles, getVehicle } from "../../../actions/vehicleActions";
import useVehicles from "../../useComponents/useVehicles";
import UseLoadStudents from "../../useComponents/UseLoadStudents";
import { getStudents, getStudent } from "../../../actions/studentesActions";
import useTeacheres from "../../useComponents/useTeachers";
import { getUsers } from "../../../actions/userActions";
import {
  getStudentTextBooks,
  fetchTextBook,
  increaseStudentBalance,
  decreaseStudentBalance,
} from "../../../actions/textBooksActions";
import { create, getDocumentNumber } from "../../../actions/incomesAction";
import { create as createObligoOrDiscount } from "../../../actions/discountsAndObligationsActions";
import CreateButton from "../../UiComponents/Buttons/Default";
import { getAll } from "../../../actions/drivingPermits";
import DefaultWrappedWithField from "../../UiComponents/Inputs/DefaultWrappedWithField";
import DefaultSelect from "../../UiComponents/Select/Default";
import DefaultDateTimePicker from "../../UiComponents/DatePicker/WithTime";
import DefaultDatePicker from "../../UiComponents/DatePicker/Default";
import NotesInput from "../../UiComponents/TextArea/Default";
import IncomeData from "../../Financial/IncomesFromStudents/IncomeData/IncomeData";
import { getNds } from "../../../utils/functions";

const paymentMethods = [
  { value: 0, label: "מזומן" },
  { value: 1, label: "שיק" },
  { value: 3, label: "העברה" },
  { value: 4, label: "אשראי" },
  { value: 5, label: "אפליקציה" },
  { value: 6, label: "סליקה" },
];

const NewEvent = ({
  isNewAgreementModalOpen,
  setNewAgreementModalIsOpen,
  handleSubmit,
  submitting,
  isStudentsLoaded,
  getStudents,
  students,
  getStudentTextBooks,
  change,
  studentId,
  isUsersLoaded,
  isVehiclesLoaded,
  users,
  reset,
  vehicles,
  getVehicles,
  paymentMethod,
  documentType,
  create,
  getDocumentNumber,
  getUsers,
  createObligoOrDiscount,
  isAllowedInvoice,
  isAllowedReceipt,
  isAllowedInvoiceReceipt,
  isAllowedCreditReceipt,
  isAllowedCreditInvoiceReceipt,
  isAllowedCreditInvoice,
  isAllowedDiscount,
  isAllowedBilling,
  studentIdFromTextBook,
  textbookId,
  user,
  increaseStudentBalance,
  decreaseStudentBalance,
  studentFromCalendar,
  defaultStudent,
  date
}) => {
  const { t, i18n } = useTranslation("common");
  const [isLoading, setIsLoading] = useState(false);
  const [allStudentTextBookData, setAllStudentTextBookData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [providerData, setProviderData] = useState(false);
  const [textbookOptions, setTextbookOptions] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [isProviderDetailsModalOpen, setProviderDetailsModalOpen] =
    useState(false);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const teachersOptions = useTeacheres(isUsersLoaded, getUsers, users);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);

  UseLoadStudents(isStudentsLoaded, getStudents);

  useEffect(() => {
    if (
      paymentMethod?.value === 1 ||
      paymentMethod?.value === 4 ||
      paymentMethod?.value === 3
    ) {
      change("dateOfMaturity", new Date());
    }
  }, [paymentMethod]);

  useEffect(() => {
    change('vat', getNds(date))
  }, [date]);

  useEffect(() => {
    const options = [];
    if (isAllowedInvoiceReceipt)
      options.push({ value: 0, label: "חשבונית/קבלה" });
    if (isAllowedReceipt) options.push({ value: 1, label: "קבלה" });
    if (isAllowedInvoice) options.push({ value: 2, label: "חשבונית" });
    if (isAllowedCreditInvoiceReceipt)
      options.push({ value: 3, label: "חשבונית/קבלה זיכוי" });
    if (isAllowedCreditReceipt) options.push({ value: 4, label: "קבלה זיכוי" });
    if (isAllowedCreditInvoice)
      options.push({ value: 5, label: "חשבונית זיכוי" });
    if (isAllowedDiscount) options.push({ value: 6, label: "הנחה" });
    if (isAllowedBilling) options.push({ value: 7, label: "חיוב" });
    change("documentType", options[0]);

    setDocumentTypes(options);
  }, []);

  useEffect(() => {
    if (!isProviderDetailsModalOpen && typeof studentId !== "undefined") {
      setNewAgreementModalIsOpen(false);
    }
  }, [isProviderDetailsModalOpen]);

  useEffect(() => {
    if (isStudentsLoaded) {
      const studentSuggestions = students.map((x) => ({
        value: x.studentId,
        label: `${x.studentId} - ${x.firstName} ${x.lastName}`,
      }));
      setSuggestions(studentSuggestions);

      if (typeof studentIdFromTextBook !== "undefined") {
        change(
          "clientId",
          studentSuggestions.find((x) => x.value === studentIdFromTextBook)
        );
      }
      if (studentFromCalendar !== null) {
        change(
          "clientId",
          studentSuggestions.find((x) => x.value === studentFromCalendar)
        );
      }
      if (defaultStudent !== null && defaultStudent !== undefined) {
        change(
          "clientId",
          studentSuggestions.find((x) => x.value === defaultStudent)
        );
      }
    }
  }, [isStudentsLoaded]);

  useEffect(() => {
    async function fetchData() {
      if (typeof documentType !== "undefined") {
        const a = await getDocumentNumber(documentType.value);

        change("documentNumber", a);
      }
    }
    fetchData();
  }, [documentType]);

  useEffect(() => {
    if (typeof studentId !== "undefined")
      (async () => {
        const fetchedStundetTextBooks = await getStudentTextBooks(
          studentId.value
        );
        if (fetchedStundetTextBooks.length === 0) {
          setIsErrorModalOpen(true);
          setErrorModalText("חסר לתלמיד תיק לימוד");
        } else {
          setAllStudentTextBookData(fetchedStundetTextBooks);
          const options = fetchedStundetTextBooks.map((x) => ({
            value: x.textBookId,
            label: x.description,
          }));
          setTextbookOptions(options);
          if (options.length === 1) change("textbookId", options[0]);
        }
      })();
  }, [studentId]);

  useEffect(() => {
    if (typeof textbookId !== "undefined") {
      const textbook = allStudentTextBookData.find(
        (x) => x.textBookId === textbookId.value
      );
      const teacher = teachersOptions.find(
        (x) => x.value.toString() === textbook.teacherId.toString()
      );
      const vehicle = vehicleOptions.find(
        (x) => x.value.toString() === textbook.vehicleId.toString()
      );
      change("teacherId", teacher);
      change("vehicleId", vehicle);
    }
  }, [textbookId]);

  const fundingBodyCreateAction = async (formValues) => {
    const documentType = formValues.documentType?.value;
    if (documentType !== 6 && documentType !== 7) {
      let incomeToSave = {
        ...formValues,
        clientId: formValues.clientId.value,
        documentType: formValues.documentType.value,
        textbookId: formValues.textbookId.value,
        teacherId: formValues.teacherId.value,
        date: new Date(formValues.date),
        createUsername: `${user.firstName} ${user.lastName}`,
      };

      if (documentType !== 2)
        incomeToSave = {
          ...incomeToSave,
          paymentMethod: formValues.paymentMethod.value,
        };

      if (formValues.paymentMethod.value === 6) {
        if (
          (formValues.ccNumber === '' || formValues.ccNumber === undefined || formValues.ccNumber === null) ||
          (formValues.ccCVV === '' || formValues.ccCVV === undefined || formValues.ccCVV === null) ||
          (formValues.ccDate === '' || formValues.ccDate === undefined || formValues.ccDate === null)
        ) {
          alert('יש למלא את כל פרטי האשראי להשלמת התשלום')
        } else {
          setIsCardLoading(true);
          const response = await create(incomeToSave);
          if (response === undefined) {
            alert('שגיאה בסליקה');
            setIsCardLoading(false);
          } else{
            setIsCardLoading(false);
            setProviderData(response);
            setProviderDetailsModalOpen(true);
          }
        }
      } else {
        const response = await create(incomeToSave);
        setProviderData(response);
        setProviderDetailsModalOpen(true);
      }

    } else {
      let incomeToSave = {
        studentId: formValues.clientId.value,
        vehicleId: formValues.vehicleId.value,
        textbookId: formValues.textbookId.value,
        teacherId: formValues.teacherId.value,
        date: new Date(formValues.date),
        notes: formValues.notes,
        amount: formValues.amount,
        isDiscount: formValues.documentType.value === 6,
      };
      await createObligoOrDiscount(incomeToSave);
      setNewAgreementModalIsOpen(false);
    }

    if (
      documentType === 6 ||
      documentType === 3 ||
      documentType === 4 ||
      documentType === 5
    ) {
      await increaseStudentBalance(
        formValues.textbookId.value,
        formValues.amount
      );
    } else {
      await decreaseStudentBalance(
        formValues.textbookId.value,
        formValues.amount
      );
    }

    reset();
  };

  return (
    <SivanModal
      titleText="מסמך כספי לתלמיד"
      modalIsOpen={isNewAgreementModalOpen}
      closeModal={() => setNewAgreementModalIsOpen(false)}
      isLoading={isLoading}
    >
      {isErrorModalOpen && (
        <ErrorModal
          modalIsOpen={isErrorModalOpen}
          closeModal={() => setIsErrorModalOpen(false)}
          text={errorModalText}
        />
      )}
      <IncomeData
        isModalOpen={isProviderDetailsModalOpen}
        closeModal={() => setProviderDetailsModalOpen(false)}
        providerData={providerData}
      />
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <div style={{ width: "100%", maxHeight: "80vh" }}>
          <Section basicData noBorder>
            <Row>
              <Field
                name="documentType"
                component={DefaultSelect}
                placeholder="סוג"
                options={documentTypes}
              />
              <Field
                name="clientId"
                component={DefaultSelect}
                options={suggestions}
                placeholder="בחר תלמיד"
              />
              <Field
                name="date"
                component={DefaultDateTimePicker}
                placeholder="תאריך ושעה"
              />
              {documentType?.value !== 6 && documentType?.value !== 7 && (
                <Field
                  name="vat"
                  component={DefaultWrappedWithField}
                  placeholder="מע״מ"
                  disabled
                />
              )}
              {documentType?.value !== 6 && documentType?.value !== 7 && (
                <Field
                  name="documentNumber"
                  component={DefaultWrappedWithField}
                  placeholder="מספר מסמך"
                  type="number"
                  disabled
                />
              )}
            </Row>
            <Row>
              <Field
                name="textbookId"
                component={DefaultSelect}
                placeholder="תיק לימוד"
                options={textbookOptions}
              />
              <Field
                name="teacherId"
                component={DefaultSelect}
                placeholder="מורה"
                options={teachersOptions}
              />
              <Field
                name="vehicleId"
                component={DefaultSelect}
                placeholder="רכב"
                options={vehicleOptions}
              />
              {documentType?.value !== 6 && documentType?.value !== 7 && (
                <Field
                  name="description"
                  component={DefaultWrappedWithField}
                  placeholder="עבור"
                />
              )}
              <Field name="notes" component={NotesInput} placeholder="הערות" />
            </Row>
            <Row>
              {documentType?.value !== 2 &&
                documentType?.value !== 6 &&
                documentType?.value !== 7 && (
                  <Field
                    name="paymentMethod"
                    component={DefaultSelect}
                    placeholder="סוג תשלום"
                    options={paymentMethods}
                  />
                )}
              <Field
                name="amount"
                component={DefaultWrappedWithField}
                placeholder="סכום"
                type="number"
              />
              {(paymentMethod === paymentMethods[1] ||
                paymentMethod === paymentMethods[2]) && (
                <Field
                  name="bank"
                  component={DefaultWrappedWithField}
                  placeholder="בנק"
                  type="number"
                />
              )}
              {(paymentMethod === paymentMethods[1] ||
                paymentMethod === paymentMethods[2]) && (
                <Field
                  name="bankBranch"
                  component={DefaultWrappedWithField}
                  placeholder="סניף"
                  type="number"
                />
              )}
              {paymentMethod === paymentMethods[1] && (
                <Field
                  name="checkNumber"
                  component={DefaultWrappedWithField}
                  placeholder="מס המחאה"
                  type="number"
                />
              )}
              {(paymentMethod === paymentMethods[1] ||
                paymentMethod === paymentMethods[2] ||
                paymentMethod === paymentMethods[4]) && (
                <Field
                  name="accountId"
                  component={DefaultWrappedWithField}
                  placeholder="חשבון"
                  type="number"
                />
              )}
              {paymentMethod === paymentMethods[2] && (
                <Field
                  name="reference"
                  component={DefaultWrappedWithField}
                  placeholder="אסמכתא"
                  type="text"
                />
              )}
              {(paymentMethod === paymentMethods[3] ||
                paymentMethod === paymentMethods[4]) && (
                <Field
                  name="confirmationNumber"
                  component={DefaultWrappedWithField}
                  placeholder="מספר אישור"
                  type="text"
                />
              )}
              {paymentMethod === paymentMethods[3] && (
                <Field
                  name="confirmationName"
                  component={DefaultWrappedWithField}
                  placeholder="שם מאשר"
                  type="text"
                />
              )}
              {(paymentMethod === paymentMethods[1] ||
                paymentMethod === paymentMethods[2] ||
                paymentMethod === paymentMethods[3]) && (
                <Field
                  name="dateOfMaturity"
                  component={DefaultDatePicker}
                  placeholder="תאריך פרעון"
                />
              )}
              {paymentMethod === paymentMethods[4] && (
                <Field
                  name="appName"
                  component={DefaultWrappedWithField}
                  placeholder="אפליקציה"
                  type="text"
                />
              )}
              {paymentMethod === paymentMethods[5] && (
                <>
                  <Field
                    name="ccNumber"
                    component={DefaultWrappedWithField}
                    placeholder="מספר כרטיס אשראי"
                    type="text"
                  />
                  <Field
                    name="ccDate"
                    component={DefaultWrappedWithField}
                    placeholder="תוקף"
                    type="text"
                  />
                  <Field
                    name="ccCVV"
                    component={DefaultWrappedWithField}
                    placeholder="CVV (3 ספרות בגב הכרטיס)"
                    type="text"
                  />
                </>
              )}
            </Row>
          </Section>
        </div>
        {isCardLoading ? (
          <>
            <h4 style={{direction: 'rtl'}}>טוען כרטיס אשראי..</h4>
            <h5 style={{direction: 'rtl'}}>אנא המתינו</h5>
          </>
        ) : (
          <CreateButton
            type="submit"
            disabled={submitting}
            text="שמירה"
            marginTop={2}
          />
        )}
      </Body>
    </SivanModal>
  );
};
const selector = formValueSelector("newCharge"); // <-- same as form name

function mapStateToProps(state, ownProps) {
  return {
    isAllowedInvoice: state.session.user.isAllowedInvoice,
    isAllowedReceipt: state.session.user.isAllowedReceipt,
    isAllowedInvoiceReceipt: state.session.user.isAllowedInvoiceReceipt,
    isAllowedCreditReceipt: state.session.user.isAllowedCreditReceipt,
    isAllowedCreditInvoiceReceipt:
      state.session.user.isAllowedCreditInvoiceReceipt,
    isAllowedCreditInvoice: state.session.user.isAllowedCreditInvoice,
    isAllowedDiscount: state.session.user.isAllowedDiscount,
    isAllowedBilling: state.session.user.isAllowedBilling,
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    isUsersLoaded: state.UsersReducer.isLoaded,
    user: state.session.user,
    users: state.UsersReducer.users,
    documentType: selector(state, "documentType"),
    paymentMethod: selector(state, "paymentMethod"),
    textbookId: selector(state, "textbookId"),
    studentId: selector(state, "clientId"),
    date: selector(state, "date"),
    students: state.StudentsReducer.students,
    isStudentsLoaded: state.StudentsReducer.isLoaded,
    initialValues: {
      date: new Date(),
      vat: getNds(),
      quantity: 1,
      paymentMethod: paymentMethods[0],
      vehicleId: { value: 999, label: "כל הרכבים" },
      amount: 0,
      description: "שיעורי נהיגה",
      isStudentInvoice: true,
    },
  };
}

export default connect(mapStateToProps, {
  getVehicles,
  getStudents,
  getUsers,
  getStudentTextBooks,
  change,
  fetchTextBook,
  getVehicle,
  getStudent,
  getAll,
  create,
  getDocumentNumber,
  createObligoOrDiscount,
  reset,
  increaseStudentBalance,
  decreaseStudentBalance,
})(
  reduxForm({
    form: "newCharge",
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate,
  })(NewEvent)
);
