export const downloadFile = (file, inNewWindow=true, fileName='') => {
  if (!file) {
    console.log('file not found');
    return;
  }
  if (inNewWindow) {
    //Build a URL from the file
    const fileURL = URL.createObjectURL(file);
    //Open the URL on new Window
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
    return;
  }
  let link = document.createElement('a');
  if (fileName.length > 0) link.download = fileName;
  link.href = URL.createObjectURL(file);
  link.click();
  URL.revokeObjectURL(link.href);
}

export const getNds = (date = null) => {
  if (date == null) date = new Date();
  date = new Date(date);
  return date.getFullYear() < 2025 ? 17 : 18;
}

export const calculateNds = (amount, date = null, returnNds = false) => {
  const nds = getNds(date);
  const ndsAmount = amount / (nds / 100 + 1);
  return !returnNds ? ndsAmount.toFixed(2) : (amount - ndsAmount).toFixed(2);
}
