import styled from "styled-components";
import i18n from "../../../lib/i18n/i18n";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const Body = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorBlock = styled.div`
  width: 100%;
  color: red;
  padding: 5px;
  border: 1px solid red;
`;

export const WarningBlock = styled.div`
  width: 100%;
  color: #dfc274;
  padding: 5px;
  border: 1px solid #dfc274;
`;

export const Button = styled.div`
  width: 14vw;
  text-align: center;
  height: 3vh;
  background-image: linear-gradient(to bottom, #ebd794, #d2ab51);
  font-size: 15pt;
  cursor: pointer;
  margin-top: 0.5vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-image: linear-gradient(to bottom, #ebd794, #b5913e);
  }
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
    div {
      padding: 5px;
      height: 34px;
      max-width: 150px;
    }
`
