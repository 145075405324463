import React, { useState } from "react";
import { connect } from "react-redux";
import SivanModal from "../../Modal/NewModal";
import { Body, ErrorBlock, Row, WarningBlock } from "./promt.styles";
import TextField from "@material-ui/core/TextField";
import Button from "../../UiComponents/Buttons/Default";

const PromtBase = ({
  children,
  isModalOpen,
  onClose,
  defaultValue,
  title,
  txtBtn1,
  txtBtn2,
  placeholder="",
  btn2DefaultValue = '',
  isLoading = false,
  warnign = '',
}) => {
  const [notes, setNotes] = useState(defaultValue);
  const [error, setError] = useState('');

  const makeAction = async (result) => {
    onClose(result);
  };

  return (
    <SivanModal
      titleText={title}
      modalIsOpen={isModalOpen}
      closeModal={() => onClose(false)}
      isLoading={isLoading}
    >
      <Body>
        {error &&
          <ErrorBlock>{{error}}</ErrorBlock>
        }
        {warnign &&
          <WarningBlock>{warnign}</WarningBlock>
        }
        {children && children}
        {!children && <TextField
          placeholder={placeholder}
          value={notes}
          onChange={(x) => setNotes(x.target.value)}
          id="outlined-basic"
          margin="dense"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
        />}
        <Row>
          <Button disabled={isLoading} onClick={() => makeAction(notes)} text={txtBtn1}/>
          <Button disabled={isLoading} onClick={() => makeAction(btn2DefaultValue)} text={txtBtn2}/>
        </Row>
      </Body>
    </SivanModal>
  );
};

export default connect(null, {})(PromtBase);
