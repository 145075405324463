import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Field, reduxForm, change } from "redux-form";
import { useTranslation } from "react-i18next";
import validate from "./validate";
import {
  Body,
  PermanentDetailsContainer,
  AdditionalDetailsContainer,
  ColumnTitle,
  Button,
  Fields,
  ButtonContainer,
} from "./createStudent.styles";
import {
  updateStudent,
  disableStudent,
  deleteStudent,
  reactivateStudent,
} from "../../../actions/studentesActions";
import { getStudentTextBooks } from "../../../actions/textBooksActions";
import { getStudentEvents } from "../../../actions/schedulerActions";
import { getBranches } from "../../../actions/branchActions";
import Alert from "../../App/Alert"
import { toast } from "react-toastify";

const genderOptions = [
  { value: "male", label: "זכר" },
  { value: "female", label: "נקבה" },
];

const CreateStudent = ({
  handleSubmit,
  renderSelect,
  renderInput,
  renderDatePickerField,
  renderTextArea,
  updateStudent,
  disableStudent,
  studentData,
  history,
  getStudentEvents,
  deleteStudent,
  getBranches,
  change,
  reactivateStudent,
  getStudentTextBooks,
  user,
}) => {
  const [isDeleteAllowed, setIsDeleteAllowed] = useState(false);
  const { t, i18n } = useTranslation("common");
  const [branchOptions, setBranchOptions] = useState([]);
  const [studentTextBooks, setStudentTextBooks] = useState([]);
  const [showAlertDisableUser, setShowAlertDisableUser] = useState(false);
  const [studentBalanse, setStudentBalanse] = useState(0);

  useEffect(() => {
    async function fetchData() {
      const optionsArray = [];
      const allBranches = await getBranches();
      allBranches.map(({ id, name }) =>
        optionsArray.push({ value: id, label: name })
      );
      const branchIndex = optionsArray.findIndex(
        (x) => x.value === studentData.branchId
      );
      setBranchOptions(optionsArray);
      if (branchIndex !== -1) change("branchId", optionsArray[branchIndex]);
    }
    fetchData();
  }, []);

  const updateStudentAndSetLoading = async (formValues) => {
    if (
      await updateStudent({
        lastName: formValues.lastName,
        firstName: formValues.firstName,
        studentId: formValues.studentId,
        birthday: formValues.birthday,
        address: formValues.address,
        city: formValues.city,
        phone: formValues.phone,
        homePhone: formValues.homePhone,
        workPhone: formValues.workPhone,
        email: formValues.email,
        notes: formValues.notes,
        business: formValues.business,
        nickname: formValues.nickname,
        gender: formValues.gender.value,
        branchId: formValues.branchId.value,
        id: formValues.id,
      })
    )
      history.push("/studentSearch");
  };

  const deleteChosenStudent = async () => {
    if (await deleteStudent(studentData.id)) history.push("/studentSearch");
  };

  const disableChosenStudent = async (data) => {
    if (studentBalanse < 0) {
      setShowAlertDisableUser(true)
    } else {
      await disableChosenStudentConfirm(2)
    }
  };

  const disableChosenStudentConfirm = async (result) => {
    setShowAlertDisableUser(false);
    if (result !== 2) return;
    const res = await disableStudent(studentData.id)
    if (res) {
      // toast.success("התלמיד אינו פעיל.");
    }
  };

  useEffect(() => {
    async function fetchData() {
      const count = await getStudentEvents(studentData.studentId);
      if (count.length === 0) {
        setIsDeleteAllowed(true);
      }
      const studentTextBook = await getStudentTextBooks(studentData.studentId)
      setStudentTextBooks(studentTextBook)
      if (studentTextBook && studentTextBook.length > 0) {
        const studentBalanse = studentTextBook.reduce((p, c) => p + parseFloat(c.balance), 0)
        setStudentBalanse(studentBalanse)
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {showAlertDisableUser && 
        <Alert 
          isModalOpen={showAlertDisableUser}
          onClose={(res) => disableChosenStudentConfirm(res)}
          title=''
          txtBtn1={t("buttons.back")}
          txtBtn2={user.userType == 4 ? t("buttons.inactive") : false}
        >
          <p style={{padding: "8px"}}>
            {user.userType == 4 ? `?התלמיד/ה ביתרת חובה. האם להעביר ללא פעיל` : "אין לך הרשאת עדכון ללא פעיל"}
          </p>
          </Alert>
      }
      <Body onSubmit={handleSubmit(updateStudentAndSetLoading)}>
        <Fields isRtl={i18n.language === "he"}>
          <PermanentDetailsContainer>
            <ColumnTitle dir="auto">
              {t("newStudent.permanentDetails")}
            </ColumnTitle>
            <Field
              name="lastName"
              component={renderInput}
              placeholder={t("newStudent.lastName")}
              disabled={!isDeleteAllowed}
            />
            <Field
              name="firstName"
              component={renderInput}
              placeholder={t("newStudent.firstName")}
              disabled={!isDeleteAllowed}
            />
            <Field
              name="studentId"
              component={renderInput}
              placeholder={t("newStudent.id")}
              type="number"
              disabled={!isDeleteAllowed}
            />
            <Field
              name="birthday"
              component={renderDatePickerField}
              placeholder={t("newStudent.birthday")}
            />
            <Field
              name="branchId"
              component={renderSelect}
              placeholder={t("newStudent.branch")}
              options={branchOptions}
            />
          </PermanentDetailsContainer>
          <AdditionalDetailsContainer>
            <ColumnTitle dir="auto">
              {t("newStudent.additionalDetails")}
            </ColumnTitle>
            <Field
              name="gender"
              component={renderSelect}
              placeholder={t("newStudent.gender")}
              options={genderOptions}
            />
            <Field
              name="nickname"
              component={renderInput}
              placeholder={t("newStudent.nickname")}
            />
            <Field
              name="address"
              component={renderInput}
              placeholder={t("newStudent.address")}
            />
            <Field
              name="city"
              component={renderInput}
              placeholder={t("newStudent.city")}
            />
            <Field
              name="postalCode"
              component={renderInput}
              placeholder={t("newStudent.postalCode")}
            />
            <Field
              name="phone"
              component={renderInput}
              placeholder={t("newStudent.phone")}
            />
          </AdditionalDetailsContainer>
          <AdditionalDetailsContainer>
            <ColumnTitle />
            <Field
              name="homePhone"
              component={renderInput}
              placeholder={t("newStudent.homePhone")}
            />
            <Field
              name="workPhone"
              component={renderInput}
              placeholder={t("newStudent.workPhone")}
            />
            <Field
              name="email"
              component={renderInput}
              placeholder={t("newStudent.email")}
            />
            <Field
              name="business"
              component={renderInput}
              placeholder={t("newStudent.business")}
            />
            <Field
              name="notes"
              component={renderTextArea}
              placeholder={t("newStudent.notes")}
            />
          </AdditionalDetailsContainer>
        </Fields>
        <ButtonContainer>
          <Button type="submit">{t("buttons.save")}</Button>
          <Button
            onClick={() =>
              studentData.active === 2
                ? reactivateStudent(studentData.id)
                : disableChosenStudent(studentData.id)
            }
            type="button"
          >
            {studentData.active === 2
              ? t("buttons.active")
              : t("buttons.inactive")}
          </Button>
          {isDeleteAllowed && (
            <Button onClick={deleteChosenStudent} type="button">
              {t("buttons.delete")}
            </Button>
          )}
        </ButtonContainer>
      </Body>
    </>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    user: state.session.user,
    initialValues: {
      ...ownProps.studentData,
      birthday: new Date(ownProps.studentData.birthday),
      gender:
        typeof ownProps.studentData.gender === "object"
          ? ownProps.studentData.gender
          : genderOptions.find(
              ({ value }) => value === ownProps.studentData.gender
            ),
    },
  };
}

export default withRouter(
  connect(mapStateToProps, {
    updateStudent,
    disableStudent,
    getStudentEvents,
    deleteStudent,
    getBranches,
    change,
    reactivateStudent,
    getStudentTextBooks,
  })(
    reduxForm({
      form: "updateStudentBasicData", // a unique identifier for this form
      destroyOnUnmount: true, // <------ preserve form data
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
      validate,
      enableReinitialize: true,
    })(CreateStudent)
  )
);
