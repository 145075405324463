import { testWhatsAppMessage } from "../constants/events";
import { formatPhoneForWhatsapp, OpenWatsApp } from "../utils/device";

export const sendWhatsAppEventMessage = function(student, event, user) {
  let _text = `
  שלום לך, `+student.firstName + ' ' + student.lastName+`,
  ברצוני להזכירך כי בתאריך `+event.start.getDate() + '/' + (event.start.getMonth() + 1).toString() + '/' + event.start.getFullYear()+`, ב`+new Date(event.start).toLocaleString('he-il', {weekday:'long'})+`,
  בשעה `+event.start.getHours() +':'+event.start.getMinutes()+`
  קבענו שיעור.
  נתראה`
  if (event.eventType == 2 || event.eventType.value == 2) _text = testWhatsAppMessage({...event, firstName: student.firstName, lastName: student.lastName }, user)
  const phone = student.phone.startsWith("0") ? student.phone.substring(1) : student.phone
  OpenWatsApp(formatPhoneForWhatsapp(phone), _text)
}

export const eventStatusIcons = {
  0: "icons8-question-mark",
  1: "icons8-checkmark",
  2: "icons8-shekel",
  3: "icons8-cancel",
  4: "icons8-cancel-with-usd",
};

export const getNameStyle = (studentData) => {
  let styles = {};
  if (studentData.internalTestStatus) {
    styles.borderTop = "1px solid black";
  }
  if (
    studentData.conversion ||
    studentData.refresh ||
    studentData.renewal ||
    studentData.control
  ) {
    styles.borderLeft = "1px solid black";
    styles.borderRight = "1px solid black";
  }
  if (
    studentData.fundingBody !== 999 &&
    studentData.fundingBody !== null &&
    typeof studentData.fundingBody !== "undefined"
  ) {
    styles.borderBottom = "1px solid black";
  }

  return styles;
};