import styled from "styled-components";
import i18n from "../../lib/i18n/i18n";
import { isMobile, isMobileOnly } from "react-device-detect";
import { isIpad } from "../../utils/device";

const currLanguage = i18n.language;
const isHebrew = currLanguage === "he";

export const ContainerVehicle = styled.div`
  .rbc-time-slot {
    min-height: 5vh !important;
  }`
export const Container = styled.div`
  .rbc-agenda-time-cell {
    vertical-align: middle;
  }
  .rbc-agenda-time-cell span{
    cursor: pointer;
  }
  .rbc-header,
  .rbc-month-view,
  .rbc-month-row,
  .rbc-day-bg,
  .rbc-timeslot-group,
  .rbc-time-content,
  .rbc-events-container,
  .rbc-time-view,
  .rbc-time-header-content {
    //border-color: #ffefca;
    border-color: #f5f5f7;
  }
  // .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  .rbc-agenda-table > tbody > tr {
    display: table-row !important;
  }
  .rbc-agenda-table thead {
    display: none;
  }

  .rbc-header, .rbc-day-bg {
    border-left: 3px solid #f0f0f0 !important;
    border-right-width: 0 !important;
  }

  .rbc-row-content {
    z-index: 0;
  }

  .rbc-time-slot {
    min-height: 15vh;
  }

  .rbc-rtl .rbc-time-content > * + * > * {
    border-right: 0 solid #ddd !important;
  }
  
  .rbc-time-header-gutter, .rbc-time-column {
    border-left: 3px solid #f0f0f0 !important;
  }

  .rbc-header {
    padding-top: ${!isMobileOnly && "5px"};
    padding-bottom: ${!isMobileOnly && "20px"};
    font-weight: ${!isMobileOnly && "bold"};
    font-size: ${!isMobileOnly && "13pt"};
    color: #fff;
    background-color: #000;
  }

  .rbc-header.rbc-today {
    color: #000;
    background-color:#eaf6ff
  }

  .rbc-agenda-event-cell,
  .rbc-agenda-time-cell {
    display: table-cell !important;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-timeslot-group:nth-child(2n + 1) {
    background-color: #f5f5f7;
  }

  .rbc-toolbar-label {
    ${isMobileOnly && "display: none;"}
  }

  .react-datepicker-wrapper {
    width: ${isMobile ? "40vw" : "10%"};
    height: ${isMobile ? "4vh" : "2.5vh"};
    margin-top: 0;
  }

  .react-datepicker-popper {
    z-index: 343443;
  }

  .rbc-agenda-content {
    max-height: 72vh;
  }

  .rbc-day-slot .rbc-events-container {
    ${isMobileOnly && "margin:0;"}
  }

  .rbc-event {
    ${isMobileOnly && "padding: 1px 2px;"}
  }
  .rbc-agenda-date-cell, .rbc-agenda-time-cell {
    display: none;
  }

  .select-toggle {
    margin-right: 20px;
  }

  .MuiFormControl-root {
    background-color: #fff;
  }
`;
export const ChapterTitle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;

export const Burger = styled.div`
  .burger-menu-wrap {
    position: fixed;
    right: inherit;
    z-index: ${(props) => (props.zIndex ? props.zIndex : "1100")};
    height: 100%;
    transition: all 0.5s ease 0s;    

    width: fit-content !important;
    max-height: 70vh;

    right: 0;
    top:${isMobileOnly ? "55px" : "145px"};

  }

  .burger-menu {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;

    background: white;

    ::-webkit-scrollbar {
      width: 0;  /* Remove scrollbar space */
      background: transparent;  /* Optional: just make scrollbar invisible */
    } 
  }
`

export const UserOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${isMobile ? "column" : "row"};
  align-items: center;
  justify-content: center;
  background-color: #f5f5f7;
  padding: 10px 10px 5px 10px 
`;

export const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
    minWidth: 150
  }),
  control: (provided, state) => ({
    ...provided,
    // height: "3vh",
    border: "1px solid #e5e5e5",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: "#c2c2c2",
    [isHebrew ? "paddingRight" : "paddingLeft"]: "0.5vw",
  }),
};

//


export const HeaderContainer = styled.div`
  //border-bottom: 1px solid #ffefca;
  border-bottom: 1px solid #fff;
  display: flex;
  flex-direction: ${(props) => (props.isRtl ? "row-reverse" : "row")};
  height: ${isMobileOnly ? "5vh" : "auto"};
  padding: ${isMobileOnly ? '5%' : isMobile ? "10px 15px 15px 15px": "10px 0 15px 0"};
  margin-bottom: ${isMobileOnly && '2%'};
  background-color: #f5f5f7;
`;

export const LoggedUserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${isMobile ? "center" : "space-between"};
  flex-direction: ${isMobile ? "column" : "row"};
  width: ${isMobile ? "55vw" : "100%"};
  padding-right: 2vw;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ffefca;
  width: 25vw;
  background-color: #fffdf9;
`;

export const Logo = styled.img`
  height: ${isMobileOnly ? "5vh" : "50px"};
  width: auto;
`;

export const LoggedUserName = styled.span`
  font-size: ${isMobileOnly ? "2vw" : "12pt"};
  font-weight: ${isMobileOnly ? 'bold' : 'normal'};
  margin-top: ${isMobileOnly ? '' : '-0pt'};
  margin-right: ${isMobileOnly ? '' : '-50pt'};
`;

export const StickyHeader = styled.div`
position: fixed;
z-index: 1000;
width: 98vw;
background-color: white;
top: 0;
`;
export const StickyBody = styled.div`
padding-top: ${isIpad ? '350px' : '300px'};
`;
export const StickyBodyMobile = styled.div`
  padding-top: 310px;
  transition: all 0.5s;
`;

export const LogoWithUserContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-end;
  background-color: #f5f5f7;
  padding: 0px 10px 0px 0px;
  width: ${isMobile ? "auto" : "20vw"};
`;

export const SelectContainer = styled.div`
  border-right: 4px solid #fff;
  background-color: #f5f5f7;
  padding: 0px 30px 0px 30px;
  width: 45vw;
`;

export const ButtonContainer = styled.div`
  border-top: ${isMobile && "4px solid #fff"};  
  border-right: ${!isMobile && "4px solid #fff"};
  background-color: #f5f5f7;
  padding: ${isMobile ? "10px 0px 0px 0px" : "0px 30px 0px 20px;"};  
  margin: ${isMobile && "10px 0px 0px 0px"};
  width: ${isMobile ? "100%" : "35vw"};
`;

export const IconSwitch = styled.i`
  cursor: pointer;
  class: ${(props) => props.className};
  color: ${(props) => props.isFoldMenu ? "#e8d290" :"#000"};
`

export const Column = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems ? props.alignItems : 'center'};
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent ? props.justifyContent : 'flex-end'};
  flex-direction: row;
  width: 100%;

  .MuiFormControl-marginDense {
    margin: 0
  }

  &.row-select {
    padding: ${isMobileOnly ? "2px" : "5px"};
  }

  .button-action {
    padding: 7px;
  }

  .button-navigation {
    width: 120px;
    padding: 7px;
    flex-grow: 1;
  }

  .split-button-navigation {
    font-size: 13pt;
    width: 120px;
    padding: 7px;
    flex-grow: 1;
    background: linear-gradient(to bottom,#ebd794,#d2ab51);
  }

  .split-button-navigation-toggle {
    background: linear-gradient(to bottom,#ebd794,#d2ab51);
  }

  .split-button-navigation {
    border-color: #fff !important;
  }

  .calendar-date-label {
    direction: rtl;
    background: #fff;
    position: relative;
    padding-left: 5px;
    padding-right: 5px;        
    width: ${!isMobile && "170px"};
    height: 38px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;
    text-align: center;
  }

  input[type="date"] {
    position: relative;
    padding-left: 5px;
    padding-right: 5px;        
    height: 38px;
    border: 1px solid #d4d4d4;
    border-radius: 4px;

    ::-webkit-calendar-picker-indicator {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: transparent;
      color: transparent;
      cursor: pointer;
      height: auto;
      width: auto;
    }

    :before {
      background: none;
      display: block;
      font-family: 'FontAwesome';
      content: '\f073';
      padding-left: 5px;
      padding-right: 5px;        
      color: #d2ab51;
    }    
  }
  
  .react-toggle-thumb {
    width: 29px;
    height: 18px;
    border-radius: 5px;
    background-color: #ffffff;    
  }

  .react-toggle-track {
    width: 56px;
    height: 18px;
    border-radius: 5px;
    background-color: #e8d290 !important;
  }

  .react-toggle--checked .react-toggle-thumb {
    background-color: #ffffff;
  }

`;


