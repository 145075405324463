import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import i18n from "../../../../lib/i18n/i18n";
import { Field, reduxForm, change, formValueSelector } from "redux-form";
import DatePicker from "react-datepicker";
import Select from "react-select";
import SivanModal from "../../../Modal/Modal";
import {
  Body,
  Section,
  Row,
  ToggleContainer,
  ToggleText,
  customStyles,
} from "./newExpense.styles";
import Toggle from "react-toggle";
import { create } from "../../../../actions/expensesActions";
import { getSchoolExpenseTypes } from "../../../../actions/expenseTypesAction";
import CreateButton from "../../../UiComponents/Buttons/Default";
import { getVehicles } from "../../../../actions/vehicleActions";
import useVehicles from "../../../useComponents/useVehicles";
import { getSchoolProviders } from "../../../../actions/providersActions";
import InputWrappedWithField from "../../../UiComponents/Inputs/DefaultWrappedWithField";
import NotesInput from "../../../UiComponents/TextArea/Default";
import DefaultSelect from "../../../UiComponents/Select/Default";
import Input from "../../../UiComponents/Inputs/Default";
import { getNds } from "../../../../utils/functions";

const documentTypes = [
  { value: 0, label: "חשבונית/קבלה" },
  { value: 1, label: "קבלה" },
  { value: 2, label: "חשבונית" },
];

const paymentMethods = [
  { value: 0, label: "מזומן" },
  { value: 1, label: "שיק" },
  { value: 3, label: "העברה" },
  { value: 4, label: "אשראי" },
  { value: 5, label: "אפליקציה" },
  { value: 6, label: "סליקה" },
];

const renderToggle = ({
  input,
  label,
  meta: { touched, error },
  type = "text",
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <ToggleContainer isRtl={isHebrew}>
      <ToggleText>{label}</ToggleText>
      <Toggle
        checked={input.value}
        onChange={(x) => input.onChange(x.target.checked ? 1 : 0)}
      />
    </ToggleContainer>
  );
};

const renderDatePickerField = ({
  input,
  placeholder,
  meta: { error, touched },
  isNoTime,
}) => {
  const isHebrew = i18n.language === "he";
  const dir = isHebrew ? "rtl" : "ltr";
  return (
    <DatePicker
      {...input}
      selected={input.value}
      dir={dir}
      placeholderText={placeholder}
      dateFormat={isNoTime ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm"}
      showTimeSelect={!isNoTime}
      className={`${touched && error ? "error" : ""} date-input`}
      locale={i18n.language}
      popperModifiers={{
        preventOverflow: {
          enabled: true,
        },
      }}
      onBlur={() => input.onBlur(input.value)}
    />
  );
};

const NewExpenseType = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  create,
  getSchoolExpenseTypes,
  expenseTypes,
  isExpenseTypesLoaded,
  getVehicles,
  vehicles,
  isProvidersLoaded,
  providers,
  isVehiclesLoaded,
  getSchoolProviders,
  change,
  expenseType,
  paymentMethod,
  amount,
  vat,
  date,
}) => {
  const [expenseTypesOptions, setExpenseTypesOptions] = useState([]);
  const [vatRecognizedSum, setVatRecognizedSum] = useState(0);
  const [providersOptions, setProvidersOptions] = useState([]);
  const vehicleOptions = useVehicles(isVehiclesLoaded, getVehicles, vehicles);

  useEffect(() => {
    async function fetchData() {
      if (!isExpenseTypesLoaded) {
        await getSchoolExpenseTypes();
      }
      if (!isProvidersLoaded) {
        await getSchoolProviders();
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (isExpenseTypesLoaded) {
      setExpenseTypesOptions(
        expenseTypes.map((x) => ({ value: x.id, label: x.name }))
      );
    }
  }, [isExpenseTypesLoaded]);

  useEffect(() => {
    if (isProvidersLoaded) {
      const options = providers.map((x) => ({ value: x.id, label: x.name }));
      setProvidersOptions(options);
    }
  }, [isProvidersLoaded]);

  useEffect(() => {
    if (isExpenseTypesLoaded && typeof expenseType !== "undefined")
      change(
        "vatRecognized",
        expenseTypes.find((x) => x.id === expenseType.value).vatRecognized
      );
  }, [expenseType]);

  useEffect(() => {
    let nds = getNds(date);
    if (isExpenseTypesLoaded && typeof expenseType !== "undefined") {
      const vatRecognized = expenseTypes.find((x) => x.id === expenseType.value)
        .vatRecognized;

      setVatRecognizedSum(amount * (((nds / 100) * vatRecognized) / 100));
    }
    change('vat', nds);
  }, [expenseType, amount, date]);

  const fundingBodyCreateAction = async (formValues) => {
    await create({
      ...formValues,
      providerId: formValues.providerId.value,
      documentType: formValues.documentType.value,
      expenseType: formValues.expenseType.value,
      paymentMethod: formValues.paymentMethod.value,
      vehicleId: formValues.vehicleId.value,
      date: new Date(formValues.date),
    });
    setIsModalOpen(false);
  };

  return (
    <SivanModal
      titleText="הוצאה חדשה"
      modalIsOpen={isModalOpen}
      closeModal={() => setIsModalOpen(false)}
    >
      <Body onSubmit={handleSubmit(fundingBodyCreateAction)}>
        <Section basicData noBorder>
          <Row>
            <Field
              name="date"
              component={renderDatePickerField}
              placeholder="תאריך ושעה"
            />
            <Field
              name="providerId"
              component={DefaultSelect}
              placeholder="בחר ספק"
              options={providersOptions}
            />
            <Field
              name="quantity"
              component={InputWrappedWithField}
              placeholder="כמות"
              type="number"
            />
            <Field
              name="expenseType"
              component={DefaultSelect}
              placeholder="סוג הוצאה"
              options={expenseTypesOptions}
            />
            <Field name="isCredit" component={renderToggle} label="זיכוי" />
            <Field
              name="documentType"
              component={DefaultSelect}
              placeholder="סוג"
              options={documentTypes}
            />
            <Field
              name="isEquipmentAndAssets"
              component={renderToggle}
              label="ציוד ונכסים קבועים"
            />
            <Field
              name="vehicleId"
              component={DefaultSelect}
              placeholder="רכב"
              options={[{ value: 999, label: "כל הרכבים" }, ...vehicleOptions]}
            />
          </Row>
          <Row>
            <Field
              name="vat"
              component={InputWrappedWithField}
              placeholder="מע״מ"
              disabled
            />
            <Field
              name="vatRecognized"
              component={InputWrappedWithField}
              placeholder="אחוז מע״מ מוכר"
              type="number"
              disabled
            />
            <Input
              value={vatRecognizedSum}
              component={InputWrappedWithField}
              placeholder="מע״מ מוכר"
              type="number"
              disabled
            />
            <Field
              name="documentNumber"
              component={InputWrappedWithField}
              placeholder="מספר מסמך ידני"
              type="number"
            />
            <Field name="notes" component={NotesInput} placeholder="הערה" />
          </Row>
          <Row>
            <Field
              name="paymentMethod"
              component={DefaultSelect}
              placeholder="סוג תשלום"
              options={paymentMethods}
            />
            <Field
              name="amount"
              component={InputWrappedWithField}
              placeholder="סכום"
              type="number"
            />
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[2]) && (
              <Field
                name="bank"
                component={InputWrappedWithField}
                placeholder="בנק"
                type="number"
              />
            )}
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[2]) && (
              <Field
                name="bankBranch"
                component={InputWrappedWithField}
                placeholder="סניף"
                type="number"
              />
            )}
            {paymentMethod === paymentMethods[1] && (
              <Field
                name="checkNumber"
                component={InputWrappedWithField}
                placeholder="מס המחאה"
                type="number"
              />
            )}
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[4]) && (
              <Field
                name="accountId"
                component={InputWrappedWithField}
                placeholder="חשבון"
                type="number"
              />
            )}
            {paymentMethod === paymentMethods[2] && (
              <Field
                name="reference"
                component={InputWrappedWithField}
                placeholder="אסמכתא"
                type="text"
              />
            )}
            {(paymentMethod === paymentMethods[3] ||
              paymentMethod === paymentMethods[4]) && (
              <Field
                name="confirmationNumber"
                component={InputWrappedWithField}
                placeholder="מספר אישור"
                type="text"
              />
            )}
            {paymentMethod === paymentMethods[3] && (
              <Field
                name="confirmationName"
                component={InputWrappedWithField}
                placeholder="שם מאשר"
                type="text"
              />
            )}
            {(paymentMethod === paymentMethods[1] ||
              paymentMethod === paymentMethods[3]) && (
              <Field
                name="dateOfMaturity"
                component={renderDatePickerField}
                placeholder="תאריך פרעון"
                isNoTime
              />
            )}
            {paymentMethod === paymentMethods[4] && (
              <Field
                name="appName"
                component={InputWrappedWithField}
                placeholder="אפליקציה"
                type="text"
              />
            )}
          </Row>
        </Section>
        <CreateButton text="צור" type="submit" />
      </Body>
    </SivanModal>
  );
};

const selector = formValueSelector("NewExpenseType"); // <-- same as form name

function mapStateToProps(state, ownProps) {
  return {
    vehicles: state.VehiclesReducer.vehicles,
    isVehiclesLoaded: state.VehiclesReducer.isLoaded,
    providers: state.ProvidersReducer.providers,
    expenseType: selector(state, "expenseType"),
    paymentMethod: selector(state, "paymentMethod"),
    amount: selector(state, "amount"),
    vat: selector(state, "vat"),
    date: selector(state, "date"),
    isProvidersLoaded: state.ProvidersReducer.isLoaded,
    expenseTypes: state.ExpenseTypesReducer.expenseTypes,
    isExpenseTypesLoaded: state.ExpenseTypesReducer.isLoaded,
    initialValues: {
      date: new Date(),
      vat: getNds(),
      paymentMethod: paymentMethods[0],
      documentType: documentTypes[0],
      vehicleId: { value: 999, label: "כל הרכבים" },
    },
  };
}

export default connect(mapStateToProps, {
  create,
  getSchoolExpenseTypes,
  getVehicles,
  getSchoolProviders,
  change,
})(
  reduxForm({
    form: "NewExpenseType",
  })(NewExpenseType)
);
